import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function LocalizationDocument() {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Localization</Typography>
                    <Divider />
                    You can localize the app in this module. By localizing the app, you can reach more users with more languages. You can add/remove language, edit languages's translations and select an language as default.
                    If user device's language is in the supported languages, it will show the language's translations in the app. Otherwise, it will show the default language's translations.
                    <br />
                    <br />
                    To access an language's translations, you need to click on it in the supported languages section. You will see the language's traanslations with JSON format in JSON Editor. Through JSON editor, you can make any modification freely to optimize the translation.
                    <b> If you don't know how to edit the JSON file, <a href="https://www.youtube.com/watch?v=t-ZK9tvF97k" target={"_blank"} rel={"noreferrer"} className={"text-primary"}> please watch this video. </a></b>
                    <br />
                    <br />
                    <b>Rules</b>
                    <br />
                    1. Please only modify <b>value</b> fields, not <b>key</b> fields. Below example: <b>name, surname</b>(nonmodifable) fields are keys. <b>Ömer, Bayrak</b>(modifable) fields are values.
                    <br />
                    <code>
                        <br />
                        {"{"}
                        <br />
                        &nbsp;&nbsp; name: "Ömer",
                        <br />
                        &nbsp;&nbsp; surname: "Bayrak"
                        <br />
                        &nbsp;&nbsp; key: "value"
                        <br />
                        {"}"}
                    </code>
                    <br />
                    <br />
                    2. If you don't need an language, please delete it totally, instead of deleting its translation's text. You can use the "Delete" button on the "Edit Translation Dialog" to delete the language totally.
                    <br />
                    <br />
                    3. You can add a new key-value pairs to use it in app bar's title, bottom navigation bar's subtitle and onboarding images so on...

                    <br />
                    <br />


                    <b>Capabilities</b>
                    <ul>
                        <li>You can localize error pages's text (no connection, 404 error and other error messages...)</li>
                        <li>You can localize app's buttons text (go home, next, skip, cancel...)</li>
                        <li>You can localize components's text (app bar's title, bottom navigation bar's subtitle, onboarding's images)</li>
                        <li>You can localize the other warning/error/information messages...</li>
                    </ul>

                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

