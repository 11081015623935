import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function GeneralSettingsDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Initial Link</Typography>
                    <Divider />
                    App uses built-in browser and open the initial link inside the browser. The initial link don't have to be home page of your website. You may want to show some specific page in the app as initially.
                    <br />
                    Let's say your website adress is <b>https://yourdomain.com/</b>
                    <br />
                    You can set the initial link as <b>https://yourdomain.com/login</b>, to show the user login page as initially.
                    <br />
                    <br />
                    <Typography variant="h5" >Primary Color</Typography>
                    <Divider />
                    Primary color is used many components in the app. For example,
                    <ul>
                        <li>Pull to refresh indicator</li>
                        <li>On boarding screen buttons</li>
                        <li>Download button(icon color)</li>
                        <li>QR code buttons(icon color)</li>
                        <li>Error screens</li>
                    </ul>

                    so on... you need to select an color that is compatible with your website's design.
                    <br />
                    <br />
                    <Typography variant="h5" >Background Color</Typography>
                    <Divider />
                    Background color is used many components in the app. For example,
                    <ul>
                        <li>Download button(background color)</li>
                        <li>QR code buttons(background color)</li>
                        <li>Background color for some other components</li>
                    </ul>

                    so on... you need to select an color that is compatible with your website's design.
                    <br />
                    <br />
                    <Typography variant="h5" >Splash Delay</Typography>
                    <Divider />
                    If you make it 0, splash screen will be disappear once app is loaded fully on the ram. But If you increase the delay to one second, It will show the splash screen one more second to the user before redirect to the user to next page. <br />
                    <i>The unit of the splash delay is millisecond.</i>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

