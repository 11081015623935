import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import useAuthUser from "../hooks/AuthUser";
import PaidUserComponent from "./Auth/PaidUserComponents";
import AdminUserComponent from "./Auth/AdminUserComponents";
import UsernameText from "./UsernameText";
import ProtectedComponent from "./Auth/ProtectedComponent";
import { reviewMode } from "../configuration";


export default function AccountButton() {

    const { onLogout } = useAuthUser();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);



    return (
        <ProtectedComponent>
            <div>
                <Grid container alignItems={"center"} >
                    <Grid item pt={0.5}>
                        <UsernameText />
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                        >
                            <MenuItem onClick={onLogout}>Logout</MenuItem>
                            <MenuItem onClick={() => navigate(`/frequently-asked-questions`)}>FAQ</MenuItem>
                            <PaidUserComponent>
                                <MenuItem onClick={() => {
                                    navigate(`/source-code`);
                                }}>Source Code</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate("/forgot-password/change_password");
                                    setAnchorEl(null);
                                }}>Change Password</MenuItem>
                            </PaidUserComponent>
                            {
                                reviewMode && (<MenuItem onClick={() => {
                                    if (window.confirm("Are you sure to delete the account? All data will be delete permanantly. Click to yes to accept")) {
                                        onLogout();
                                    }
                                }}>Delete Account</MenuItem>)
                            }
                        </Menu>
                    </Grid>
                </Grid>
            </div>
        </ProtectedComponent >
    );
}
