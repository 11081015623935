import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PeopleIcon from '@mui/icons-material/People';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter7Icon from '@mui/icons-material/Filter7';
import { useParams } from "react-router-dom";
import ConstructionIcon from '@mui/icons-material/Construction';
import useAnalyticsIntegrationDialog from "../hooks/AnalyticsIntegrationDialog";
import useAnalytics from "../hooks/backend/Analytics";


export default function Dashboard() {
    const { fetchAnalytics, components, UiStream } = useAnalytics();
    const [AIDialog, openAIDialog] = useAnalyticsIntegrationDialog();
    const [analytics, setAnalytics] = useState();
    const [loading, setLoading] = useState(true);
    const [isAnalyticsIntegrated, setIsAnalyticsIntegrated] = useState(true);
    const { project } = useParams();

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        } else if (UiStream.value && UiStream.value.includes("integration_is_not_completed_error")) {
            setIsAnalyticsIntegrated(false);
        }

    }, [UiStream]);




    const doFetchAnalytics = async () => {
        setIsAnalyticsIntegrated(true);
        setLoading(true);

        const _analytics = await fetchAnalytics();

        setAnalytics(_analytics);

        if (!_analytics) {
            return;
        }

        // var _reports = [];

        /*for (const report of _analytics.reports) {
            var metric = {};
            if (report.rows.length !== 0) {
                for (const row of report.rows) {
                    metric[`${row.dimensionValues[0].value}`] = row.metricValues[0].value;
                }
            } else {
                metric = { iOS: 0, Android: 0, total: 0 };
            }

            metric["Android"] = metric["Android"] ? metric["Android"] * 1 : 0;
            metric["iOS"] = metric["iOS"] ? metric["iOS"] * 1 : 0;


            metric["total"] = metric["Android"] + metric["iOS"];
            _reports.push(metric);
        }*/
        //setAnalytics(_reports);
        setLoading(false);
    }

    var getAnalytic = (index, platform) => {
        return analytics[index] ? analytics[index][platform] : 0;
    }

    useEffect(() => {
        doFetchAnalytics();
    }, []);

    return (
        <div>
            {
                isAnalyticsIntegrated && analytics ?
                    (
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <MetricCard loading={loading} title={"Total Users"} iOSCount={analytics["Total Users"]["iOS"]} androidCount={analytics["Total Users"]["Android"]} color={"#52BE80"} icon={<PeopleIcon sx={{ fontSize: "60px" }} />} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <MetricCard loading={loading} title={"Monthly Active Users"} iOSCount={analytics["Monthly Active Users"]["iOS"]} androidCount={analytics["Monthly Active Users"]["Android"]} color={"#5DADE2"} icon={<SentimentSatisfiedAltIcon sx={{ fontSize: "60px" }} />} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <MetricCard loading={loading} title={"Weekly Active Users"} iOSCount={analytics["Weekly Active Users"]["iOS"]} androidCount={analytics["Weekly Active Users"]["Android"]} color={"#A569BD"} icon={<Filter7Icon sx={{ fontSize: "60px" }} />} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <MetricCard loading={loading} title={"Daily Active Users"} iOSCount={analytics["Daily Active Users"]["iOS"]} androidCount={analytics["Daily Active Users"]["Android"]} color={"#EB984E"} icon={<Filter1Icon sx={{ fontSize: "60px" }} />} />
                            </Grid>

                        </Grid>
                    ) : <Grid container justifyContent={"center"} mt={5}>
                        <Button variant="contained" onClick={async () => {
                            var result = await openAIDialog(project);
                            if (result) {
                                doFetchAnalytics();
                            }
                        }} color="warning" startIcon={<ConstructionIcon />}>
                            Click Here to Fix The Problem
                        </Button>
                    </Grid>
            }

            {AIDialog}


            {
                components.map((component, index) => (
                    <div key={index}>
                        {component}
                    </div>
                ))
            }
        </div>

    );
}


function MetricCard({ title, count, iOSCount, androidCount, color, loading, icon }) {
    return (<Grid container>
        <Grid item xs={12} className="metric-card">
            <Grid container height={"100%"}>
                <Grid item xs={8} px={3} py={2}>
                    <Typography variant="h5" color={color}>{title}</Typography>
                    {
                        loading ? (<Grid container height={"100%"} mt={2} >
                            <CircularProgress sx={{ color }} />
                        </Grid>) : (<Grid>
                            <Typography variant="h6" color={color} >{(iOSCount * 1) + (androidCount * 1)}</Typography>
                            <Typography variant="body1" color={"GrayText"} >iOS: {iOSCount}</Typography>
                            <Typography variant="body1" color={"GrayText"} >Android: {androidCount}</Typography>
                        </Grid>)
                    }

                </Grid>
                <Grid item xs={4} height={"100%"}>
                    <Grid container alignItems={"center"} height={"100%"} justifyContent={"center"}>
                        <Grid item color={color}>
                            {icon}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}
