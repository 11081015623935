import { Box, Button, CircularProgress, Grid } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';




export default function ProjectFormTemplate(props) {
    return (
        <Box>
            {props.loading ? (
                <Grid container sx={{ height: "300px" }} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                    <Grid item  >
                        <CircularProgress color="primary" />
                    </Grid>
                </Grid>
            ) : (<Grid container >
                <Grid item xs={12}>
                    <Box noValidate sx={{ mt: 0 }}  >
                        {props.children}
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}>

                        {props.footer}

                        <Grid item>
                            <Button
                                onClick={props.handleSaveButton}
                                color="secondary"
                                startIcon={props.saveButtonIcon ?? <SaveIcon />}
                                variant="contained"
                            >
                                {props.saveButtonText ?? 'Save'}
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>)
            }



            {
                props.rcComponents.map((component, index) => (
                    <div key={index}>
                        {component}
                    </div>
                ))
            }
        </Box >
    );
}