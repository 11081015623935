import { Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, ListItemText, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useForm } from "react-hook-form";
import TranslationTextField from "./TranslationTextField";



export default function ListFormControl({ list, label, onAdd, helperText, onDelete, translationTextField, remoteConfig }) {

    const { register, handleSubmit, formState: { errors } } = useForm();


    const [newItem, setNewItem] = useState("");

    const [_list, _setList] = useState([]);

    useEffect(() => {
        _setList(list);
    }, [list]);



    return (
        <Grid container sx={{ mt: 0 }}>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} spacing={2} justifyContent={"space-between"} >
                    <Grid item xs={12}>
                        {
                            translationTextField ? (
                                <Grid container alignItems={"top"}>
                                    <Grid item xs={11}>
                                        <TranslationTextField
                                            value={newItem}
                                            label={label}
                                            helperText={helperText}
                                            remoteConfig={remoteConfig}
                                            onChange={(value) => setNewItem(value)}
                                        />
                                    </Grid>
                                    <Grid item xs={1} mt={0.5}>
                                        <IconButton color="primary" size="large" onClick={() => {
                                            onAdd(newItem);
                                            setNewItem("");
                                        }} >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ) : (
                                <FormControl size="small" fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={errors?.textfield}
                                        id="textfield"
                                        name="textfield"
                                        value={newItem}
                                        {...register("textfield", { required: 'This field is required' })}
                                        type="url"
                                        color='primary'
                                        autoComplete="text"
                                        label={label}
                                        autoFocus
                                        onChange={(e) => setNewItem(e.target.value)}
                                        endAdornment={
                                            <IconButton color="primary" onClick={handleSubmit(() => {
                                                onAdd(newItem);
                                                setNewItem("");
                                            })} >
                                                <AddIcon />
                                            </IconButton>
                                        }
                                    />
                                    <FormHelperText error={errors?.textfield}>{errors?.textfield?.message ?? helperText}</FormHelperText>
                                </FormControl >
                            )
                        }


                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <List dense={false} sx={{ borderBottom: "1px" }} >
                    {_list?.map((item, index) => (
                        <nav key={index}><ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="arrow" color="secondary" onClick={() => onDelete(item)} >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <InsertLinkIcon color="primary" sx={{ mr: 3 }} />

                            <ListItemText className="text-primary"
                                primary={item}
                            />
                        </ListItem>
                            <Divider />
                        </nav>
                    ))}
                </List>
            </Grid>
        </Grid >
    );
}
