import { Alert, Button, ButtonGroup, FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { SAVE_ALERT } from "../../../../../consts";
import useRemoteConfig from "../../../../../hooks/backend/RemoteConfig";
import useSnackbar from "../../../../../hooks/Snackbar";
import FirebasePanelLink from "../../../../FirebasePanelLink";
import ProjectFormTemplate from "../ProjectFormTemplate";
import AppOpenAdsDialog from "./AppOpenAdsDialog";
import BannerAdsDialog from "./BannerAdsDialog";
import InterstitialAdsDialog from "./InterstitialAdsDialog";



export default function MobileAdsForm() {
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);
    const [snackbar, openSnackbar] = useSnackbar();

    // form
    const [active, setActive] = useState(true);
    const [nonPersonalizedAds, setNonPersonalizedAds] = useState(true);
    const [keywords, setKeywords] = useState("");
    const [appOpenAds, setAppOpenAds] = useState({});
    const [bannerAds, setBannerAds] = useState({});
    const [interstitialAds, setInterstitialAds] = useState({});

    // helper
    const [appOpenAdsDialogOpen, setAppOpenAdsDialogOpen] = useState(false);
    const [bannerAdsDialogOpen, setBannerAdsDialogOpen] = useState(false);
    const [interstitialAdsDialogOpen, setInterstitialAdsDialogOpen] = useState(false);




    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.mobile_ads) {
            setActive(remoteConfig.mobile_ads.active);
            setNonPersonalizedAds(remoteConfig.mobile_ads.non_personalized_ads);
            setKeywords(remoteConfig.mobile_ads.keywords.join(','));
            setAppOpenAds(remoteConfig.mobile_ads.app_open_ads);
            setBannerAds(remoteConfig.mobile_ads.banner_ads);
            setInterstitialAds(remoteConfig.mobile_ads.interstitial_ads);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        remoteConfig.mobile_ads.non_personalized_ads = nonPersonalizedAds;
        remoteConfig.mobile_ads.keywords = keywords.split(',');
        remoteConfig.mobile_ads.app_open_ads = appOpenAds;
        remoteConfig.mobile_ads.banner_ads = bannerAds;
        remoteConfig.mobile_ads.interstitial_ads = interstitialAds;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            saveDisabled={!active}
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            {!active ? (<Alert color="warning" severity="warning">
                Please go to <FirebasePanelLink /> {"->"} Remote Config {"->"} Configuration, then active the mobile ads on the admin panel.
                <br />
                <a rel="noreferrer" target={"_blank"} href="https://drive.google.com/file/d/1-V3AN40ILPO1a4EzsRdo-J2Kul9XH8JZ/view?usp=sharing" className="text-primary">Please watch the video to see how to do it.</a>
                <br />
                <br />
                However, you shouldn't activate the mobile ads module on admin panel, if you didn't activated it on the app. Please refer to the document to see how to activate it on the app.
            </Alert>) : null}

            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} name="active" />
                        }
                        label="Active" className="text-primary disable" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={nonPersonalizedAds} onChange={(e) => setNonPersonalizedAds(e.target.checked)} name="active" />
                        }
                        label="Non Personalized Ads" className="text-primary" labelPlacement="end"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        helperText="Type the keywords which relative to your app, and split them with comma ','"
                        name="keywords"
                        color='primary'
                        label="Keywords"
                        value={keywords}
                        type="text"
                        id="keywords"
                        onChange={(e) => setKeywords(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ButtonGroup fullWidth orientation="horizontal" aria-label="outlined button group">
                        <Button variant={"outlined"} onClick={() => {

                            setAppOpenAdsDialogOpen(true);

                        }}>App Open Ads</Button>
                        <Button variant={"outlined"} onClick={() => {
                            setBannerAdsDialogOpen(true);
                        }}>Banner Ads</Button>
                        <Button variant={"outlined"} onClick={() => {
                            setInterstitialAdsDialogOpen(true);
                        }}>Interstitial Ads</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <AppOpenAdsDialog open={appOpenAdsDialogOpen} onSave={(ads) => {
                setAppOpenAds(ads);
                openSnackbar("success", SAVE_ALERT);
                setAppOpenAdsDialogOpen(false);
            }} appOpenAds={appOpenAds} onCancel={() => setAppOpenAdsDialogOpen(false)} />
            <BannerAdsDialog open={bannerAdsDialogOpen} onSave={(ads) => {
                setBannerAds(ads);
                openSnackbar("success", SAVE_ALERT);
                setBannerAdsDialogOpen(false);
            }} bannerAds={bannerAds} onCancel={() => setBannerAdsDialogOpen(false)} />
            <InterstitialAdsDialog open={interstitialAdsDialogOpen} onSave={(ads) => {
                setInterstitialAds(ads);
                openSnackbar("success", SAVE_ALERT);
                setInterstitialAdsDialogOpen(false);
            }} interstitialAds={interstitialAds} onCancel={() => setInterstitialAdsDialogOpen(false)} />
            {snackbar}
        </ProjectFormTemplate >
    );
}