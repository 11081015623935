import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import FormTemplate from '../components/FormTemplate';
import useSnackbar from '../hooks/Snackbar';


export default function ForgotPasswordPage() {


    const [email, setEmail] = useState('');
    const [snackbar, openSnackbar] = useSnackbar();

    const { param } = useParams();

    useState(() => {
        if (param === "change_password") {
            openSnackbar("info", "Please use 'Forgot Password' feature to change your password");
        }
    }, [param]);



    const handleSubmit = (event) => {
        event.preventDefault();

        sendPasswordResetEmail(getAuth(), email).then((_) => {
            openSnackbar("success", "Mail is sent, please check spam section also");
        }).catch((error) =>
            openSnackbar("error", error.message)
        );
    };

    return (
        <FormTemplate title="Forgot Password Form">
            {snackbar}
            <Grid container justifyContent={'center'}>
                <Grid item container xs={12} sm={8} md={6}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            color='secondary'
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            color='secondary'
                            sx={{ mt: 3, mb: 2 }}
                        >

                            Submit
                        </Button>
                    </Grid>


                    <Grid item xs={12} >
                        <Typography variant="body" >
                            Have account? <Link to="/signin"  > Login </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </FormTemplate>
    );
}