import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import AccountButton from "./AccountButton";
import AppBreadcrumbs from "./AppBreadcrumbs";
import ProtectedComponent from "./Auth/ProtectedComponent";


export default function Header(props) {

    const { breadcrumbList } = props;


    return (
        <ProtectedComponent>
            <Grid container sx={{ px: 3, mb: 1 }} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <AppBreadcrumbs breadcrumbList={breadcrumbList} />
                </Grid>
                <Grid item>
                    <AccountButton />
                </Grid>
            </Grid>
        </ProtectedComponent>
    );
}
