
import useAuth from "../hooks/AuthUser";

export default function ProjectDocumentShortcut() {

    const { user } = useAuth();

    return (
        <a onClick={(e) => {
            e.preventDefault();
            document.getElementById("project-document").click();
        }} href="#" className="text-primary">
            document
        </a>
    );
}
