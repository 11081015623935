import { ThemeProvider } from '@emotion/react';
import { Container } from '@mui/material';
import * as React from 'react';
import FormTemplate from '../../components/FormTemplate';
import { brandName } from '../../consts';
import { darkTheme } from '../../theme';

export default function BlakelyChickenAppPage() {



    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >

                    <h1 align="center">Blakely Chicken App | {brandName}</h1>

                    <p align="center">
                        <table>
                            <tr>
                                <td>
                                    <a href="https://apps.apple.com/us/app/blakely-chicken/id1557481963"
                                        target="_blank">
                                        <img width="40px" alt="App Store Icon"
                                            src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                    </a>
                                </td>
                                <td>
                                    <a href="https://play.google.com/store/apps/details?id=com.blakelychicken"
                                        target="_blank">
                                        <img width="40px" alt="Google Play Icon"
                                            src="https://img.icons8.com/color/48/000000/google-play.png" />

                                    </a>
                                </td>
                                <td>
                                    <a href="https://blakelychicken.com" target="_blank">
                                        <img width="40px" alt="Website Icon"
                                            src="https://img.icons8.com/ios/50/000000/geography.png" />
                                    </a>
                                </td>
                            </tr>

                        </table>
                    </p>

                    <p style={{ textAlign: "center" }}>

                        This is the project which I did before. It mainly has Webview, push notification, internet control, splash page, pull to refresh, bottom navigation bar, loading screen, app rating features.

                        The splash page's animation is provided by buyer as a gif format. If you give me animated gif like that, I can use it in your application also. Otherwise we can use logo in splash page
                    </p>
                    <br />
                    <p>
                        <h3 >1-Overview</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1pt7-tp0JWw-onJPkyofxVrUyMeqouhi3/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >2-Push Notification Feature</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1uBZtfND4aUyUr0KNxR7eAupS6I_R2v6G/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >3-Onboarding Screen Feature</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1U0ONZMYCvOKfKAGlN-ANvCNKPh8hOYXQ/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >4-Internet Control Feature</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1EGTgPq2Vq3Q7ne4nSRpBOf04pUDeGDl-/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >5-App Rating Feature</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1QKEwCjP1ukRvmafx7laa-98oSu2Phnnl/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                </Container>
            </FormTemplate >

        </ThemeProvider >
    );
}