import { useEffect } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useAuth from '../../hooks/AuthUser';

export const ProtectedPage = ({ children }) => {
    const navigate = useNavigate();

    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/signin");

        }
    }, [isAuthenticated, navigate]);



    return (
        <div>
            {isAuthenticated && children}
        </div>
    );
};