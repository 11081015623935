import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function BottomControlBarDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Bottom Control Bar</Typography>
                    <Divider />
                    Bottom Control Bar is nice component which stands on the most bottom of the screen. It contains functional back, forward and refresh buttons.
                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1Qxc8HQReZVjVwqUoe2qk_qXJA-yyW3Yx/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the bottom control bar image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

