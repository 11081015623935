import { Alert, Autocomplete, Divider, FormControl, FormHelperText, TextField } from "@mui/material";
import { useState } from "react";




export default function TranslationTextField({ remoteConfig, label, value, onChange, helperText, ...otherParams }) {
    const [options, setOptions] = useState([]);


    useState(() => {
        setOptions(Object.getOwnPropertyNames(remoteConfig.localization.translations[remoteConfig.localization.default_locale]));
    }, [remoteConfig]);

    return (
        <FormControl fullWidth>
            <Autocomplete
                freeSolo
                disableClearable
                value={value}
                title={label}
                onChange={(_, value) => onChange(value)}
                options={options}
                renderInput={(params) => {
                    return <TextField {...params} {...otherParams}  {...otherParams} placeholder={`Pick an translation key, or type an custom ${label.toLowerCase()}`} label={label} onChange={(event, _) => onChange(event.target.value)} />;
                }}
            />
            <FormHelperText>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
}

/*
            onChange={(event, newValue) => {
                setSelectedLanguage(newValue);
            }} */