import { Avatar, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, ThemeProvider } from "@mui/material";
import { darkTheme } from "../theme";
import { useEffect, useState } from "react";
import FormTemplate from "../components/FormTemplate";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import useProjects from "../hooks/firestore/Projects";
import { formatProjectName, isDemoAccount } from "../helpers";
import ConstructionIcon from '@mui/icons-material/Construction';
import useServiceAccount from "../hooks/storage/ServiceAccount";
import useSnackbar from "../hooks/Snackbar";
import useAuth from "../hooks/AuthUser";
import { reviewMode } from "../configuration";
import AddAppDialog from "../components/AddAppDialog";
import TryDemoAppDialog from "../components/TryDemoAppDialog";
import { onlyLogo } from "../consts";


export default function ProjectsPage() {

    const { fetchProjects, updateProjects, components, UiStream } = useProjects();
    const { uploadServiceAccount } = useServiceAccount();
    const [addAppDialog, setAddAppDialog] = useState(false);
    const [tryDemoAppDialog, setTryDemoAppDialog] = useState(false);
    const [snackbar, openSnackbar] = useSnackbar();

    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    const { user } = useAuth();


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);


    useEffect(() => {
        doFetchProjects();
        if (isDemoAccount(user.email)) {
            setTimeout(() => setTryDemoAppDialog(true), 1500);
        }

    }, []);

    var doFetchProjects = async () => {
        setProjects(await fetchProjects());
    }



    const handleProjectClick = (project) => {
        navigate(`/project/${project.name}/dispatcher`);
    }

    return (
        <ThemeProvider theme={darkTheme}>

            <FormTemplate>
                <Header breadcrumbList={[{ name: "Projects", link: "/projects" }]} ></Header>
                {
                    reviewMode && (<Grid container justifyContent={"end"} pr={5} pb={1}>
                        <Grid item>
                            <Button variant="contained" onClick={() => {
                                setAddAppDialog(true);
                            }} color="primary" startIcon={<ConstructionIcon />}>
                                Add Your App
                            </Button>
                        </Grid>
                    </Grid>)
                }
                <Container style={{ "background": "white", "borderRadius": "12px" }}>

                    <List dense={false}>
                        {projects.length > 0 ? projects.map((project) =>
                        (
                            <ListItem key={project.name}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="arrow" sx={{ color: darkTheme.palette.background.default }} onClick={() => handleProjectClick(project)} >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                }>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: darkTheme.palette.background.default }}>
                                        <img src={require(`../assets/${onlyLogo}`)} width="25" alt='app logo' />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText className="text-primary"
                                    primary={formatProjectName(project.name)}
                                />
                            </ListItem>
                        )) : <Grid container justifyContent={"center"} sx={{ color: "black", p: "5px" }}>
                            {loading ? "App List is Loading" : (<Button variant="contained" onClick={() => {

                                setAddAppDialog(true);
                            }} color="primary" startIcon={<ConstructionIcon />}>
                                Add Your App
                            </Button>)}
                        </Grid>}
                    </List>
                </Container>

                {
                    components.map((component, index) => (
                        <div key={index}>
                            {component}
                        </div>
                    ))
                }
                <TryDemoAppDialog
                    open={tryDemoAppDialog}
                    onCancel={() => {
                        setTryDemoAppDialog(false);
                    }}
                />
                <AddAppDialog
                    open={addAppDialog}
                    onSave={async (_project) => {
                        try {
                            if (reviewMode) {
                                setAddAppDialog(false);
                                return;
                            }

                            updateProjects(user.uid, [{ name: _project.name }]);
                            await uploadServiceAccount(_project.name, user.uid, _project.serviceAccount);
                            openSnackbar("success", "App is added");
                            setAddAppDialog(false);
                            doFetchProjects();
                        } catch (err) {
                            openSnackbar("error", err);
                            throw err;
                        }
                    }}
                    onCancel={() => {
                        setAddAppDialog(false);
                    }} />
                {snackbar}
            </FormTemplate>
        </ThemeProvider>
    );
}