import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function PreferencesForm(props) {



    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    const [addFirebaseTokenToInitialURL, setAddFirebaseTokenToInitialURL] = useState(true);
    const [pullToRefresh, setPullToRefresh] = useState(true);
    const [appUpgrader, setAppUpgrader] = useState(true);
    const [verticalOrientations, setVerticalOrientations] = useState(true);
    const [locationPermission, setLocationPermission] = useState(true);
    const [cameraPermission, setCameraPermission] = useState(true);
    const [storagePermission, setStoragePermission] = useState(true);
    const [supportZoom, setSupportZoom] = useState(true);
    const [verticalScrollBarEnabled, setVerticalScrollBarEnabled] = useState(true);
    const [horizontalScrollBarEnabled, setHorizontalScrollBarEnabled] = useState(true);
    const [disableVerticalScroll, setDisableVerticalScroll] = useState(true);
    const [disableHorizontalScroll, setDisableHorizontalScroll] = useState(true);
    const [mediaPlaybackRequiresUserGesture, setMediaPlaybackRequiresUserGesture] = useState(true);
    const [disableContextMenu, setDisableContextMenu] = useState(true);
    const [iosAllowLinkPreview, setIOSAllowLinkPreview] = useState(true);
    const [iosDisableLongPressContextMenuOnLinks, setIOSDisableLongPressContextMenuOnLinks] = useState(true);
    const [pageNotFoundErrorScreen, setPageNotFoundErrorScreen] = useState(true);
    const [errorScreen, setErrorScreen] = useState(true);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.preferences) {
            setAddFirebaseTokenToInitialURL(remoteConfig.preferences.add_firebase_token_to_initial_url);
            setPullToRefresh(remoteConfig.preferences.pull_to_refresh);
            setAppUpgrader(remoteConfig.preferences.app_upgrader);
            setVerticalOrientations(remoteConfig.preferences.vertical_orientations);
            setLocationPermission(remoteConfig.preferences.location_permission);
            setCameraPermission(remoteConfig.preferences.camera_permission);
            setStoragePermission(remoteConfig.preferences.storage_permission);
            setSupportZoom(remoteConfig.preferences.support_zoom);
            setVerticalScrollBarEnabled(remoteConfig.preferences.vertical_scroll_bar_enabled);
            setHorizontalScrollBarEnabled(remoteConfig.preferences.horizontal_scroll_bar_enabled);
            setDisableVerticalScroll(remoteConfig.preferences.disable_vertical_scroll);
            setDisableHorizontalScroll(remoteConfig.preferences.disable_horizontal_scroll);
            setMediaPlaybackRequiresUserGesture(remoteConfig.preferences.media_playback_requires_user_gesture);
            setDisableContextMenu(remoteConfig.preferences.disable_context_menu);
            setIOSAllowLinkPreview(remoteConfig.preferences.ios_allow_link_preview);
            setIOSDisableLongPressContextMenuOnLinks(remoteConfig.preferences.ios_disable_long_press_context_menu_on_links);
            setPageNotFoundErrorScreen(remoteConfig.preferences.page_not_found_error_screen);
            setErrorScreen(remoteConfig.preferences.error_screen);

            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.preferences.add_firebase_token_to_initial_url = addFirebaseTokenToInitialURL;
        config.preferences.pull_to_refresh = pullToRefresh;
        config.preferences.app_upgrader = appUpgrader;
        config.preferences.vertical_orientations = verticalOrientations;
        config.preferences.location_permission = locationPermission;
        config.preferences.camera_permission = cameraPermission;
        config.preferences.storage_permission = storagePermission;
        config.preferences.support_zoom = supportZoom;
        config.preferences.vertical_scroll_bar_enabled = verticalScrollBarEnabled;
        config.preferences.horizontal_scroll_bar_enabled = horizontalScrollBarEnabled;
        config.preferences.disable_vertical_scroll = disableVerticalScroll;
        config.preferences.disable_horizontal_scroll = disableHorizontalScroll;
        config.preferences.media_playback_requires_user_gesture = mediaPlaybackRequiresUserGesture;
        config.preferences.disable_context_menu = disableContextMenu;
        config.preferences.ios_allow_link_preview = iosAllowLinkPreview;
        config.preferences.ios_disable_long_press_context_menu_on_links = iosDisableLongPressContextMenuOnLinks;
        config.preferences.page_not_found_error_screen = pageNotFoundErrorScreen;
        config.preferences.error_screen = errorScreen;

        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={addFirebaseTokenToInitialURL} onChange={(e) => setAddFirebaseTokenToInitialURL(e.target.checked)} name="active" />
                        }
                        label="Add Firebase Token To Initial Url" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={pullToRefresh} onChange={(e) => setPullToRefresh(e.target.checked)} name="active" />
                        }
                        label="Pull To Refresh" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={appUpgrader} onChange={(e) => setAppUpgrader(e.target.checked)} name="active" />
                        }
                        label="App Upgrader" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={verticalOrientations} onChange={(e) => setVerticalOrientations(e.target.checked)} name="active" />
                        }
                        label="Vertical Orientations" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={locationPermission} onChange={(e) => setLocationPermission(e.target.checked)} name="active" />
                        }
                        label="Location Permission" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={cameraPermission} onChange={(e) => setCameraPermission(e.target.checked)} name="active" />
                        }
                        label="Camera Permission" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={storagePermission} onChange={(e) => setStoragePermission(e.target.checked)} name="active" />
                        }
                        label="Storage Permission" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={supportZoom} onChange={(e) => setSupportZoom(e.target.checked)} name="active" />
                        }
                        label="Support Zoom" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={verticalScrollBarEnabled} onChange={(e) => setVerticalScrollBarEnabled(e.target.checked)} name="active" />
                        }
                        label="Vertical Scroll Bar Enabled" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={horizontalScrollBarEnabled} onChange={(e) => setHorizontalScrollBarEnabled(e.target.checked)} name="active" />
                        }
                        label="Horizontal Scroll Bar Enabled" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={disableVerticalScroll} onChange={(e) => setDisableVerticalScroll(e.target.checked)} name="active" />
                        }
                        label="Disable Vertical Scroll" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={disableHorizontalScroll} onChange={(e) => setDisableHorizontalScroll(e.target.checked)} name="active" />
                        }
                        label="Disable Horizontal Scroll" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={mediaPlaybackRequiresUserGesture} onChange={(e) => setMediaPlaybackRequiresUserGesture(e.target.checked)} name="active" />
                        }
                        label="Media Playback Requires User Gesture" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={disableContextMenu} onChange={(e) => setDisableContextMenu(e.target.checked)} name="active" />
                        }
                        label="Disable Context Menu" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={iosAllowLinkPreview} onChange={(e) => setIOSAllowLinkPreview(e.target.checked)} name="active" />
                        }
                        label="IOS Allow Link Preview" className="text-primary" labelPlacement="end"
                    />
                </Grid>                <
                    Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={iosDisableLongPressContextMenuOnLinks} onChange={(e) => setIOSDisableLongPressContextMenuOnLinks(e.target.checked)} name="active" />
                        }
                        label="IOS Disable Long Press Context Menu On Links" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={pageNotFoundErrorScreen} onChange={(e) => setPageNotFoundErrorScreen(e.target.checked)} name="active" />
                        }
                        label="Page Not Found Error Screen" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={errorScreen} onChange={(e) => setErrorScreen(e.target.checked)} name="active" />
                        }
                        label="Error Screen" className="text-primary" labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}