import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function BottomNavigationBarDialog(props) {
    const [open, setOpen] = React.useState(false);

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();


    // form
    const [title, setTitle] = React.useState("");
    const [size, setSize] = React.useState(0);
    const [activeColorPrimary, setActiveColorPrimary] = React.useState("#000000");
    const [activeColorSecondary, setActiveColorSecondary] = React.useState("#000000");
    const [inactiveColorPrimary, setInactiveColorPrimary] = React.useState("#000000");
    const [inactiveColorSecondary, setInactiveColorSecondary] = React.useState("#000000");
    const [url, setURL] = React.useState("");
    const [javascript, setJavascript] = React.useState("");
    const [iconCode, setIconCode] = React.useState("");
    const [iconFamily, setIconFamily] = React.useState("");
    const [iconColor, setIconColor] = React.useState("#000000");

    // helper
    const [buttonType, setButtonType] = React.useState("url");
    const [iconJSON, setIconJSON] = React.useState({});
    const [overrideColor, setOverrideColor] = React.useState(false);





    React.useEffect(() => { setOpen(props.open); }, [props.open]);
    React.useEffect(() => {
        if (props.navBarItem.item) {
            const item = { ...props.navBarItem.item }
            setTitle(item.title);
            setSize(item.size);
            setActiveColorPrimary(item.active_color_primary);
            setActiveColorSecondary(item.active_color_secondary);
            setInactiveColorPrimary(item.inactive_color_primary);
            setInactiveColorSecondary(item.inactive_color_secondary);
            setJavascript(item.javascript);
            handleIconFamilySelection(item.icon.font_family);
            setTimeout(() => setIconCode(item.icon.icon_code), 1000);
            setIconColor(item.icon.color);
            setOverrideColor(item.icon.color !== "");
            setButtonType(item.url !== "" ? "url" : "javascript");
            setURL(item.url);
            clearErrors();
        }
    }, [props.navBarItem]);

    const handleIconFamilySelection = (value) => {
        setIconFamily(value);
        var _iconJSON = require("./icons/" + value + ".json");
        setIconJSON(_iconJSON);
        setIconCode("");
    }
    return (
        <div>
            <Dialog open={open} onClose={props.onCancel} fullScreen>
                <DialogTitle>Edit Button</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                required
                                helperText={errors?.title?.message ?? "Title of the bottom bar button"}
                                error={errors?.title}
                                fullWidth
                                name="title"
                                color='primary'
                                label="Title"
                                value={title}
                                {...register("title", { required: 'This field is required' })}
                                type="text"
                                id="title"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                helperText="The selected button's icon color of the bottom bar menu"
                                name="active_background_color"
                                color='primary'
                                label="Active Background Color"
                                value={activeColorPrimary}
                                type="color"
                                id="active_background_color"
                                onChange={(e) => setActiveColorPrimary(e.target.value)}
                                autoComplete="active_background_color"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                helperText="The selected button's background color of the bottom bar menu"
                                fullWidth
                                name="active_icon_color"
                                color='primary'
                                label="Active Icon Color"
                                value={activeColorSecondary}
                                type="color"
                                id="active_icon_color"
                                onChange={(e) => setActiveColorSecondary(e.target.value)}
                                autoComplete="active_icon_color"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                helperText="The unselected buttons's background color"
                                name="inactive_background_color"
                                color='primary'
                                label="Inactive Background Color"
                                value={inactiveColorSecondary}
                                type="color"
                                id="inactive_background_color"
                                onChange={(e) => setInactiveColorSecondary(e.target.value)}
                                autoComplete="inactive_background_color"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                helperText="The unselected buttons's icon color"
                                name="inactive_icon_color"
                                color='primary'
                                label="Inactive Icon Color"
                                value={inactiveColorPrimary}
                                type="color"
                                id="inactive_icon_color"
                                onChange={(e) => setInactiveColorPrimary(e.target.value)}
                                autoComplete="inactive_icon_color"
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Button Type</InputLabel>
                                <Select
                                    labelId="initial_index_id"
                                    id="initial_index_select"
                                    value={buttonType}
                                    label="Button Type"
                                    onChange={(e) => setButtonType(e.target.value)}
                                >
                                    <MenuItem key={1} value={"url"}>URL</MenuItem>
                                    <MenuItem key={2} value={"javascript"}>Javascript</MenuItem>

                                </Select>
                                <FormHelperText>
                                    If you select URL, button will redirect the users to the inputted url below. <br />
                                    If you select Javascript, button will run the javascript code.
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                buttonType === "url" ? (<Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="url"
                                        color='primary'
                                        label="URL"
                                        value={url}
                                        type="url"
                                        id="url"
                                        onChange={(e) => setURL(e.target.value)}
                                    />
                                </Grid>) : (
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="javascript"
                                        color='primary'
                                        label="javascript"
                                        value={javascript}
                                        type="text"
                                        id="url"
                                        onChange={(e) => setJavascript(e.target.value)}
                                    />
                                )
                            }
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Icon Family</InputLabel>
                                <Select
                                    labelId="initial_index_id"
                                    id="initial_index_select"
                                    value={iconFamily}
                                    label="Button Type"
                                    onChange={(e) => handleIconFamilySelection(e.target.value)}
                                >
                                    <MenuItem value={"AntDesign"}>AntDesign</MenuItem>
                                    <MenuItem value={"Entypo"}>Entypo</MenuItem>
                                    <MenuItem value={"EvilIcons"}>EvilIcons</MenuItem>
                                    <MenuItem value={"Feather"}>Feather</MenuItem>
                                    <MenuItem value={"FontAwesome"}>FontAwesome</MenuItem>
                                    <MenuItem value={"FontAwesome5_Brands"}>FontAwesome5_Brands</MenuItem>
                                    <MenuItem value={"FontAwesome5_Solid"}>FontAwesome5_Solid</MenuItem>
                                    <MenuItem value={"FontAwesome5"}>FontAwesome5</MenuItem>
                                    <MenuItem value={"Foundation"}>Foundation</MenuItem>
                                    <MenuItem value={"Ionicons"}>Ionicons</MenuItem>
                                    <MenuItem value={"MaterialCommunityIcons"}>MaterialCommunityIcons</MenuItem>
                                    <MenuItem value={"MaterialIcons"}>MaterialIcons</MenuItem>
                                    <MenuItem value={"Octicons"}>Octicons</MenuItem>
                                    <MenuItem value={"SimpleLineIcons"}>SimpleLineIcons</MenuItem>
                                    <MenuItem value={"WeatherIcons"}>WeatherIcons</MenuItem>
                                    <MenuItem value={"Zocial"}>Zocial</MenuItem>
                                </Select>
                                <FormHelperText>
                                    Click <a rel="noreferrer" className='text-primary' href='https://oblador.github.io/react-native-vector-icons/' target={'_blank'}>here</a> to see all the icons.
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Icon Name</InputLabel>
                                <Select
                                    labelId="initial_index_id"
                                    id="initial_index_select"
                                    value={iconCode}
                                    label="Button Type"
                                    onChange={(e) => setIconCode(e.target.value)}
                                >
                                    {Object.keys(iconJSON).map((key) => (<MenuItem key={iconJSON[key]} value={iconJSON[key]}>{key}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                helperText={errors?.size?.message ?? "Size of the bottom bar button icon"}
                                error={errors?.size}
                                name="size"
                                color='primary'
                                label="Icon Size"
                                value={size}
                                {...register("size", { min: { value: 0, message: "This fields must more than zero" }, max: { value: 50, message: "This fields must less than 50" } })}
                                type="number"
                                id="size"
                                onChange={(e) => setSize(e.target.value)}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                helperText="This will override both 'Active Icon Color' and  'Inactive Icon Color' for the button. You need to override below swich to override it."
                                name="icon_color"
                                color='primary'
                                label="Icon Color"
                                value={iconColor}
                                type="color"
                                id="icon_color"
                                onChange={(e) => setIconColor(e.target.value)}
                                autoComplete="icon_color"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={overrideColor} onChange={(e) => setOverrideColor(e.target.checked)} name="override_icon_color" />
                                }
                                label="Override Icon Colors" className="text-primary" labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onDelete}>Delete</Button>
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button onClick={handleSubmit(() => {
                        var button = {
                            "item": {
                                "title": title,
                                "size": size * 1,
                                "active_color_primary": activeColorPrimary,
                                "active_color_secondary": activeColorSecondary,
                                "inactive_color_primary": inactiveColorPrimary,
                                "inactive_color_secondary": inactiveColorSecondary,
                                "url": buttonType === "url" ? url : "",
                                "javascript": buttonType === "javascript" ? javascript : "",
                                "scroll_to_top": false,
                                "icon": {
                                    "icon_code": iconCode,
                                    "font_family": iconFamily,
                                },
                            }


                        };
                        if (overrideColor) {
                            button.item.icon.color = iconColor;
                        }
                        props.onSave(button);
                    })}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
