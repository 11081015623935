import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ProjectFormTemplate from "./ProjectFormTemplate";
import ListFormControl from "../../../ListFormControl";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";




export default function InAppLinksForm(props) {

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [inAppLinks, setInAppLinks] = useState([]);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.in_app_links) {
            setInAppLinks(remoteConfig.in_app_links);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.in_app_links = inAppLinks;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ListFormControl
                        label="In App Link"
                        list={inAppLinks}
                        helperText={"The links in the list will open always inside the app whether if it is external link or not. Add '*' then, all external links will be open in the app"}
                        onAdd={(_argNewItem) => {
                            if (inAppLinks) {
                                setInAppLinks([...inAppLinks, _argNewItem]);

                            } else {
                                setInAppLinks([_argNewItem]);
                            }
                        }}
                        onDelete={(_argItem) => {
                            setInAppLinks(inAppLinks.filter(v => v !== _argItem));
                        }}
                    />
                </Grid>
            </Grid>

        </ProjectFormTemplate>
    );
}