import { ThemeProvider } from '@emotion/react';
import { Container } from '@mui/material';
import * as React from 'react';
import FormTemplate from '../../components/FormTemplate';
import { brandName } from '../../consts';
import { darkTheme } from '../../theme';

export default function AdminPanelPage() {



    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >

                    <h1 align="center">Admin Panel | {brandName}</h1>

                    <p style={{ textAlign: "justify" }}>
                        Through the admin panel, you can change the features of the app
                        remotely, such as; initial link, bottom navigation bar, app bar,
                        loading page and other features in the gig description...
                    </p>


                    <p style={{ textAlign: "justify" }}>
                        In this way, you will be able to change any feature of the app
                        immediately when you need it. And, you won’t have to upgrade
                        the apps on the store for those changes.
                    </p>

                    <p>
                        You can visit the admin panel yourself to see it deeply. <br />
                        Hostname: <a className='text-primary' href='https://fiverr-omerbyrk.web.app/' target={'_blank'}>https://fiverr-omerbyrk.web.app/</a> <br />
                        Username: demo@fiverr.com<br />
                        Password: 111111 <br />
                    </p>
                    <p>
                        <h3 >1-Overview</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1lDFpG7K1e_BnakWt1ixNhADkFz3U6WhS/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>

                    <br />
                    <p>
                        <h3 >2-Push Notification Feature</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1g5Y4TXWcDIZr0WxIjjQwgseKsgSbnVZ7/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >3-App Bar</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/190sNmOgfoy_XcLMIL9fuJDWdmRRMoFlW/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >4-Onboarding Screen</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1SH9V7JOxwcGj3MkXDfFTDzXp6MHPrWYJ/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                    <br />
                    <p>
                        <h3 >5-External Link</h3>
                        <div className='responsive-container'>
                            <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1_CsaUzKXtVpCduIDFFygqJFOp8Wi_EnB/preview" width="100%" height="100%" allow="autoplay" allowFullScreen></iframe>
                        </div>
                    </p>
                </Container>
            </FormTemplate >

        </ThemeProvider >
    );
}