import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function AppBarDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >App Bar</Typography>
                    <Divider />
                    App Bar is nice component which stands on the top of the screen. It contains an title, and back button. Through back button, user be able to go back page on app.
                    <br />
                    <br />
                    <b>Active: </b>will appear/disappear  app bar.  <br />
                    <b>Keep default:</b> If you make it enable, it will show the current web page title on app bar, If making disable, it will show the default title on app bar.  <br />
                    <b>Center title:</b> The title fo the app bar will be centered if it is enabled.  <br />
                    <b>Default title:</b> It is usually name of the app or you can customize it. <br />
                    <b>Background color:</b> App bar's background color  <br />
                    <b>Foreground color:</b> App bar's foreground color  <br /><br />
                    <b>Note 1:</b> App bar's color should be compatible with status bar's color.  <br />
                    <b>Note 2:</b> You can't change the app bar font size in this version.  <br />
                    <br />
                    Futher, you can check <a rel="noreferrer" href="https://drive.google.com/file/d/1ZCMcOLOnVFNrSy0aRq0De7_0KjvKT8e7/view?usp=sharing" target={"_blank"} className="text-primary">this video</a> to see how it works. And <a rel="noreferrer" href="https://drive.google.com/file/d/1thHsTqsPnGNsDx1CHiL7qOKu5tjlHnZ7/view?usp=sharing" target={"_blank"} className="text-primary">click here </a> to see the app bar image!

                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

