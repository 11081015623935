import * as React from 'react';
import { useParams } from 'react-router-dom';
import { API_FUNCTIONS, FIREBASE_FUNCTIONS, getRemoteConfigLocalStorageKey } from '../../consts';
import useAuthUser from '../AuthUser';
import useSnackbar from '../Snackbar';
import usePushNotificationDialog from './dialogs/PushNotificationDialog';
import useRemoteChangesAlertDialog from './dialogs/RemoteChangesAlertDialog';

var RCTMessages = {
    alertTitle: "Confirm the Changes",
    alertBody: "Are you sure to want to change the remote configuration file? \n\n The changes will be available on app side asap.",
    snackbarSeverity: "success",
    snackbarBody: "Remote Configuration file is changed Succesfully! The changes will be available on app side asap.",
};


export default function useRemoteConfig() {
    const { project } = useParams();
    const [remoteConfig, setRemoteConfig] = React.useState({});
    const [snackbar, setSnackbar] = useSnackbar();
    const [remoteChangesAlertDialog, showRemoteChangesAlertDialog] = useRemoteChangesAlertDialog();
    const [pushNotificationDialog, showPushNotificationDialog] = usePushNotificationDialog();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { user } = useAuthUser();

    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }


    React.useEffect(() => {

        var storageKey = getRemoteConfigLocalStorageKey(project);

        if (localStorage[storageKey] != null) {
            setRemoteConfig(JSON.parse(localStorage[storageKey]));
            return;
        }

        API_FUNCTIONS.getRemoteConfigTemplate(user.uid, project).then((result) => result.json())
            .then((result) => {
                var _remoteConfig = result;
                _remoteConfig.parameters.configuration.defaultValue.value = JSON.parse(_remoteConfig.parameters.configuration.defaultValue.value);
                setRemoteConfig(_remoteConfig);
                localStorage[storageKey] = JSON.stringify(_remoteConfig);
            }).catch((error) => {
                setSnackbar("error", error.message);
            }).finally(() => {
                _setUiStream("stop_loading");
            })
    }, [project]);



    return {
        remoteConfig: remoteConfig?.parameters?.configuration?.defaultValue?.value, // get configuration
        updateRemoteConfig: async (_remoteConfig) => {
            var storageKey = getRemoteConfigLocalStorageKey(project);
            var result = await showRemoteChangesAlertDialog(RCTMessages.alertTitle, RCTMessages.alertBody);
            var notifyUsers = result.notifyUsers ? true : false;
            if (result.OK) {
                _setUiStream("start_loading");
                remoteConfig.parameters.configuration.defaultValue.value = JSON.stringify(_remoteConfig);
                API_FUNCTIONS.updateRemoteConfigTemplate({ useruid: user.uid, project, notifyUsers, template: remoteConfig })
                    .then((_) => {
                        remoteConfig.parameters.configuration.defaultValue.value = _remoteConfig;
                        setSnackbar(RCTMessages.snackbarSeverity, RCTMessages.snackbarBody);
                        setRemoteConfig(remoteConfig);
                        localStorage[storageKey] = JSON.stringify(remoteConfig);
                    }).catch((error) => {
                        setSnackbar("error", error.message);
                    }).finally(() => {
                        _setUiStream("stop_loading");
                    })
            }
        },
        UiStream,
        components: [snackbar, remoteChangesAlertDialog, pushNotificationDialog]
    };
}

/*

*/

/*
                    v */