import { Alert, Snackbar } from '@mui/material';
import * as React from 'react';

export default function useSnackbar() {
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState(null);
    const [message, setMessage] = React.useState(null);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const snackbar = React.useMemo(() => {
        return (
            <div>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} >
                    <Alert onClose={handleClose} severity={severity ?? "error"} sx={{ width: '100%' }}>
                        {message ?? "unset"}
                    </Alert>
                </Snackbar>
            </div>
        );
    }, [open, severity, message]);

    return [
        snackbar,
        (_severity = String, _message) => {
            setSeverity(_severity);
            setMessage(_message);
            setOpen(false);
            setTimeout(() => setOpen(true), 500);
        },
    ];
}