import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Divider, FormLabel, Grid } from '@mui/material';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { getLanguageName } from '../../../../../helpers';
import ProjectDocumentShortcut from '../../../../ProjectDocumentShortcut';


export default function EditTranslationDialog({ open, langCode, value, onCancel, onSave, onDelete }) {

    const [translation, setTranslation] = React.useState({});



    React.useEffect(() => {
        setTranslation(value);
    }, [value])

    return (
        <div>
            <Dialog open={open} onClose={onCancel} fullScreen  >
                <DialogTitle>Edit Translation - {getLanguageName(langCode)}</DialogTitle>
                <DialogContent>
                    <Grid container height={"100%"} width={"100%"}>
                        <Grid item xs={12} my={1}>
                            <Alert color='info' severity='info'>
                                <code>
                                    key: 'value'
                                </code>
                                {" ->"} please only edit <b>value</b> fields. Furthermore, You can add new key-value pairs to use on the app bar, onboarding screen and bottom navigation bar so on...If you don't know how to edit the JSON Editor, please refer to the <ProjectDocumentShortcut />.
                            </Alert>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <FormLabel>JSON Editor</FormLabel>
                            <JSONInput
                                height={"100%"}
                                width={"100%"}
                                placeholder={translation}
                                locale={locale}
                                onChange={(value) => setTranslation(value.jsObject)}
                                style={{ body: { fontSize: 18, padding: '10px 8px', borderRadius: '8px' } }}
                                theme={"light_mitsuketa_tribute"}
                                colors={{ background: "#F5F5F5" }}
                                onBlur={(value) => setTranslation(value.jsObject)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDelete} color="error">Delete</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={() => {
                        onSave(translation);
                    }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
