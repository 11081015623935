import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import {
    Link,
} from "react-router-dom";
import FormTemplate from '../components/FormTemplate';
import useSnackbar from '../hooks/Snackbar';
import useAuth from '../hooks/AuthUser';
import { demoAccount } from '../consts';






export default function SignInPage() {

    const { onLogin } = useAuth();

    const [email, setEmail] = useState(demoAccount.email);
    const [password, setPassword] = useState(demoAccount.password);

    const [snackbar, openSnackbar] = useSnackbar();

    const handleSubmit = (event) => {
        event.preventDefault();
        const authentication = getAuth();
        signInWithEmailAndPassword(authentication, email, password)
            .then((result) => onLogin(result.user))
            .catch((error) =>
                openSnackbar("error", error.message)
            );
    };

    return (
        <FormTemplate title="Sign In Form">
            {snackbar}
            <Grid container justifyContent={'center'}>
                <Grid item container xs={12} sm={8} md={6}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            color='secondary'
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            color='secondary'
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleSubmit}
                            fullWidth
                            variant="contained"
                            color='secondary'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body" >
                            <Link to={'/signup'} ariant="body2" >
                                Do not have account?
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                        <Typography variant="body" >
                            <Link to={'/forgot-password'} ariant="body2" >
                                Forgot password?
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </FormTemplate >
    );
}

/*

*/