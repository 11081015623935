import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function UserAgentForm(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [android, setAndroid] = useState("");
    const [iOS, setiOS] = useState("");

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.user_agent) {
            setAndroid(remoteConfig.user_agent.android);
            setiOS(remoteConfig.user_agent.ios);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.user_agent.android = android;
        config.user_agent.ios = iOS;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        error={errors?.android}
                        helperText={errors?.android?.message ?? "By Android User Agent, you can understand the users comes from the android app, and customize the website to the app users"}
                        required
                        fullWidth
                        id="android"
                        label="Android Webview"
                        name="android"
                        value={android}
                        {...register("android", { required: 'This field is required' })}
                        type="text"
                        autoComplete="text"
                        autoFocus
                        onChange={(e) => setAndroid(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        error={errors?.iOS}
                        helperText={errors?.iOS?.message ?? "By iOS User Agent, you can understand the users comes from the iOS app, and customize the website to app users"}
                        fullWidth
                        id="iOS"
                        label="iOS Webview"
                        name="iOS"
                        value={iOS}
                        {...register("iOS", { required: 'This field is required' })}
                        type="text"
                        autoComplete="text"
                        onChange={(e) => setiOS(e.target.value)}
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}