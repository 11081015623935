
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { API_FUNCTIONS, FIREBASE_FUNCTIONS } from '../../consts';
import useAuthUser from '../AuthUser';
import useSnackbar from '../Snackbar';
import usePushNotificationDialog from './dialogs/PushNotificationDialog';


var PNMessages = {
    alertTitle: "Push Notification",
    alertBody: "Are you sure to push the notification to the users?",
    snackbarSeverity: "success",
    snackbarBody: "The notification is pushed to the users succesfully",
};

export default function useNotification() {
    const { project } = useParams();
    const [snackbar, setSnackbar] = useSnackbar();
    const [pushNotificationDialog, showPushNotificationDialog] = usePushNotificationDialog();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { user } = useAuthUser();

    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }



    return {
        pushNotification: async (_notification) => {
            var result = await showPushNotificationDialog(PNMessages.alertTitle, PNMessages.alertBody);
            if (result.OK) {
                _setUiStream("start_loading");
                API_FUNCTIONS.pushNotification({ project: project, useruid: user.uid, notification: { ..._notification } })
                    .then((_) => {
                        setSnackbar(PNMessages.snackbarSeverity, PNMessages.snackbarBody);
                    }).catch((error) => {
                        setSnackbar("error", error.message);
                    }).finally(() => {
                        _setUiStream("stop_loading");
                    })
            }
        },
        UiStream,
        components: [snackbar, pushNotificationDialog]
    };
}

/*

*/

/*
                    v */