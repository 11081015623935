import { Grid, ThemeProvider } from "@mui/material";
import { darkTheme } from "../theme";
import FormTemplate from "../components/FormTemplate";
import { Container } from "@mui/system";
import Header from "../components/Header";




export default function TargetedNotificationDocument() {
    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Header breadcrumbList={[{ name: "Projects", link: "/projects" }, { name: "Targeted Notification", link: "/targeted-notification" }]} ></Header>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >
                    <Grid container px={"8px"} py={"16px"}>
                        <Grid item>
                            <Grid container justifyContent={"center"}>
                                <b style={{ "fontSize": "20px" }}>Targeted Notification</b>
                            </Grid>
                            I will explain to you how to push targeted notification instead of mass notification. To do that, you need to make some changes
                            on your website's too. There will be 2 step to achive this goal.
                            <br />
                            <br />
                            <b>1. Receive Firebase Device Token</b>
                            <br />
                            Each user have unique firebase device token. You need to have this token to push targeted notification to the user.
                            To get this token, you need to activate the <b>Add Firebase Token To Initial Url</b> feature on the <b>Preferences</b> section in Admin Panel.
                            After you activate the feature, the app will add the token to the initial link of the app.
                            <br />
                            <br />
                            For example: the initial link is https://yourdomain.com
                            <br />
                            It will be converted to https://yourdomain.com?<b>firebase_token={"{token_value}"}</b> after the feature is enabled.
                            <br />
                            <br />
                            Finally, you can access the token once the user enter to the website via the app. After user login, you will understand whom the token belongs to, then save it to the database.
                            <br />
                            <br />
                            <b>2. Push Targeted Notification</b>
                            <br />
                            You can use the firebase device token to push targeted notification to only single user.
                            If you are using PHP, you can use the integration code below. Otherwise, you have to write the code in your language.
                            <br />
                            <br />
                            <a rel="noreferrer" target={"_blank"} href="https://drive.google.com/file/d/1Vi32G9stYbg0Y51gvFD-JLR8zJCJb_GY/view?usp=sharing" className="text-primary">Please click here to access the PHP code.</a>
                            <br />
                            <a rel="noreferrer" target={"_blank"} href="https://drive.google.com/file/d/14xFgtSD7fE84kIW7w8OrNUhresWzO08S/view?usp=sharing" className="text-primary">Please watch the prove video to see how it is working. </a>
                            <br />
                            <br />
                            <b>Note: </b> There is some differances between the PHP code and the prove video code. Don't be confused about that, the PHP code is updated to the app's latest version. You should use the PHP code.

                        </Grid>
                    </Grid>

                </Container>
            </FormTemplate >
        </ThemeProvider >
    );
}


