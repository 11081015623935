import { Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getLanguageName } from "../../../../../helpers";
import useRemoteConfig from "../../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "../ProjectFormTemplate";
import AddIcon from '@mui/icons-material/Add';
import AddLocaleDialog from "./AddLocaleDialog";
import EditTranslationDialog from "./EditTranslationDialog";
import useSnackbar from "../../../../../hooks/Snackbar";
import { SAVE_ALERT } from "../../../../../consts";



export default function LocalizationForm() {

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);
    const [snackbar, openSnackbar] = useSnackbar();

    // form
    const [defaultLocale, setDefaultLocale] = useState("en");
    const [supportedLocales, setSupportedLocales] = useState(["en"]);
    const [translations, setTranslations] = useState({});

    // helper
    const [addLocaleDialogOpen, setAddLocaleDialogOpen] = useState(false);
    const [editTranslationDialogOpen, setEditTranslationDialogOpen] = useState(false);
    const [selectedTranslation, setSelectedTranslation] = useState({});
    const [selectedLocale, setSelectedLocale] = useState({});


    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.localization) {
            setDefaultLocale(remoteConfig.localization.default_locale);
            setSupportedLocales(remoteConfig.localization.supported_locales);
            setTranslations(remoteConfig.localization.translations);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.localization.default_locale = defaultLocale;
        config.localization.supported_locales = supportedLocales;
        config.localization.translations = translations;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    const upsertTranslation = (langCode, translation) => {
        const _translations = translations;
        _translations[langCode] = { ...translation };
        setTranslations(_translations);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Default Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={defaultLocale}
                            label="Default Language"
                            onChange={(e) => setDefaultLocale(e.target.value)}
                        >
                            {supportedLocales.map((langCode) => (<MenuItem key={langCode} value={langCode}>{getLanguageName(langCode)}</MenuItem>))}
                        </Select>
                        <FormHelperText>
                            If user device's language is not in the supported languages, it will show the default language's translations.
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <FormLabel><Typography variant="button">Supported Languages</Typography>  </FormLabel>
                            </Grid>
                            <Grid item>
                                <Button color="primary" size="small" variant="outlined" endIcon={<AddIcon />} onClick={() => {
                                    setAddLocaleDialogOpen(true);
                                }} > Add Language
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={0.01}>
                            {supportedLocales.map((langCode) => (<Grid item key={langCode}><Button size="large" variant={langCode === defaultLocale ? "contained" : "outlined"}
                                onClick={() => {
                                    setSelectedLocale(langCode);
                                    setSelectedTranslation({ ...translations[langCode] });
                                    setEditTranslationDialogOpen(true);
                                }}>
                                {getLanguageName(langCode)}
                            </Button>
                            </Grid>))}
                        </Grid>
                        <FormHelperText>
                            Please click the buttons to edit <b>it's translations</b>.
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <AddLocaleDialog
                open={addLocaleDialogOpen}
                onCancel={() => setAddLocaleDialogOpen(false)}
                onSave={(langCode) => {
                    if (supportedLocales.indexOf(langCode) === -1) {
                        setSupportedLocales([...supportedLocales, langCode]);
                        upsertTranslation(langCode, translations[defaultLocale]);
                        openSnackbar("success", SAVE_ALERT);
                    } else {
                        openSnackbar("warning", "The language is already added.");
                    }

                    setAddLocaleDialogOpen(false);

                }}
            />

            <EditTranslationDialog
                open={editTranslationDialogOpen}
                onCancel={() => setEditTranslationDialogOpen(false)}
                value={selectedTranslation}
                langCode={selectedLocale}
                onSave={(translation) => {
                    if (translation) {
                        upsertTranslation(selectedLocale, translation);
                        setEditTranslationDialogOpen(false);
                        openSnackbar("success", SAVE_ALERT);
                    } else {
                        openSnackbar("error", "There is an format error, please check the document to learn how to edit it correctly.");
                    }
                }}
                onDelete={() => {
                    if (selectedLocale !== defaultLocale) {
                        supportedLocales.splice(supportedLocales.indexOf(selectedLocale), 1);
                        delete translations[selectedLocale];
                        setEditTranslationDialogOpen(false);
                        openSnackbar("success", SAVE_ALERT);
                    } else {
                        openSnackbar("warning", "You can't delete default language!");
                    }
                }}
            />
            {snackbar}
        </ProjectFormTemplate>
    );
}
