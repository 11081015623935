import { ThemeProvider } from '@emotion/react';
import { Container } from '@mui/material';
import * as React from 'react';
import FormTemplate from '../../components/FormTemplate';
import { brandName } from '../../consts';
import { darkTheme } from '../../theme';

export default function PortfolioPage() {



    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >

                    <h1 align="center">Portfolio | {brandName}</h1>
                    <p style={{ textAlign: "justify" }}>
                        Hey, actually I have developed more than +200 apps. However, I have listed below some of them
                        for my portfolio. I hope you can find the app which similar to your need on the list. Thank you.
                    </p>

                    <p style={{ color: "red", textAlign: "justify" }}>
                        Note: <b>Google Play</b>, <b>App Store</b> and <b>Website</b> icons are clickable. Please click on it to be
                        redirected to the app on stores and web.
                    </p>

                    <p align="center">

                        <table>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img width=" 125px" alt="Webview App Logo"
                                                    src="https://lh3.googleusercontent.com/96Gxw1AxTkQ2xb51fIvkUbWq3VKA7G8RvyIQ25gJb86SqcpP6Z05lMG1akJdAV0mlpU=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Politics and War</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/politics-and-war/id1552461332"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.game.politicsandwar"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://politicsandwar.com" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Text based Game</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img width=" 125px" alt="Webview App Logo"
                                                    src="https://lh3.googleusercontent.com/7dV62u_5HGw1aL8jGo36e83SOD1zCY9hzXaldM_-gm4pfiIbTHvUduCYgqQ27Vz_cKI=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>RepJesus Online</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/repjesus-online/id1639157612"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=online.repjesus.repjesus"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://www.repjesus.com" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Social Media</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh5.googleusercontent.com/862KkOnRuDIPLARnnjElA7QCoUHyXIjZKz1NsYDnIEDrvR0O20ACOcHQRzlU_Bg_kTo=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>ROXN Shop</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/roxn-shop/id6443916372"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.roxn.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://roxn.de" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Shopping</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh4.googleusercontent.com/6O5aqQMn3F5Vw6y-9ZpjNLkwur2MjVO-1mbC74zaMPWuNklaDo4XHygTA49EMZ4u6ig=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Local Serve</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/local-serve/id1603825043"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=localserve.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://localserve.com" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong>Food & Drink</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh4.googleusercontent.com/_QRE-eDzmzQtfLnmrULB2hJelR4x8PcV4BPKY9gXCqNuQwNg5neBUbPQhprMBqsEVFY=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>SM4U</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/sm4u/id1608787040"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=soulmate4U.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://soulmate4u.co.uk/" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Life Style - Finding Partner</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh3.googleusercontent.com/CqvgtDqnsHFH4kX0PjymV9UFcm6BwLPttt8PxDPlIqFXLcltusBLcERAQ0cXoGTdtmM=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>GSM-Abonnementen Vergelijken</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/gsm-abonnementen-vergelijken/id1644329943"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.beste_gsm_abonnement.webvify.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://beste-gsm-abonnement.be" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Productivity</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh4.googleusercontent.com/nlamxEqKTK_fxpB-M4fCVWQyDx5JjMADvKhIyfyH7SA6t3MOn3uq7keKsLeNp_-FEew=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Atakancan İddaa</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/atakancan-i-ddaa/id1551458645"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.atakancaniddaa"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://atakancaniddaa.com/" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Sport</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh6.googleusercontent.com/IlA8JPqoFQSvz5xJKWoKPJYU81tG2-tebu3y4UKnOucudK-fmSEl2HlhPNRAbNdCRf0=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Shop Online</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/shoponline/id1579155759"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=shoponline.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://www.shoponline.ly" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Shopping</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh3.googleusercontent.com/_JPOy3I0-SA2UmTkhyxbethlSbUqh7QuyrFFdXbp86HsTLOzncokQr0LpSlsKO_Fqr4=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>HOHOHI</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/hohohi/id6443593794"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.hohohi.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://hohohi.com/" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Education</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh5.googleusercontent.com/eGlNQlqCUGaFggU5BqVxq4k28RVM_b3xd6JBwc2mZM5pTVflErlvqEsi9UAbWx9mRcM=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Nolurbak</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/nolurbak-test-i-%C3%A7erik-haber/id1575884693"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=com.nolurbak.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://nolurbak.com" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Entertainment & News</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh3.googleusercontent.com/PHn8LAWrUoiVC2odyhY7XEhxo-KsEOYjRrfDDTavLTAqv91--VWCkYg7MeuUD7tobSU=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>Imam Connect</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/imam-connect/id1601096834"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://play.google.com/store/apps/details?id=imamconnect.app"
                                                                            target="_blank">
                                                                            <img width="30px" alt="Google Play Icon"
                                                                                src="https://img.icons8.com/color/48/000000/google-play.png" />

                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a href="https://www.imamconnect.com" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Business</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tr>
                                            <td>
                                                <img alt="Webview App Logo" width="125px"
                                                    src="https://lh3.googleusercontent.com/jJ7vGNRyDQBwd3nSJ7nUDvgoHcXsADB4ii4AigGi16N8mlg79M2n5al-ocT2Ck3HS0o=w2400" />
                                            </td>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;
                                            </td>
                                            <td>
                                                <table>
                                                    <tr>
                                                        <td>
                                                            <strong>RTC Radio</strong>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <a href="https://apps.apple.com/us/app/rtc-radio/id1613582178"
                                                                            target="_blank">
                                                                            <img width="32.5px" alt="App Store Icon"
                                                                                src="https://img.icons8.com/color/48/000000/apple-app-store--v3.png" />
                                                                        </a>
                                                                    </td>

                                                                    <td>
                                                                        <a href="https://www.rtcradio.co.uk" target="_blank">
                                                                            <img width="27px" alt="Website Icon"
                                                                                src="https://img.icons8.com/ios/50/000000/geography.png" />
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span><strong>Category:</strong> Music</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>

                    </p>
                </Container>
            </FormTemplate >

        </ThemeProvider >
    );
}