import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Copyright from './Copyright';
import { darkTheme } from '../theme';
import { Divider } from '@mui/material';
import { logoWhite } from '../consts';




export default function FormTemplate(props) {
    return (
        <ThemeProvider theme={darkTheme}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src={require(`../assets/${logoWhite}`)} width="200" alt='app logo' />

                    <Typography variant="h6" sx={{ mt: 5 }}>{props.title}</Typography>
                    <Divider sx={{ mt: 3 }} />
                    {props.children}
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}