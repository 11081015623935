import { getAuth } from 'firebase/auth'
import { firebaseAdmins, firebaseDemos } from './configuration';
import { languages } from './consts';

export const getAuthUser = async () => {
    return await new Promise((resolve, reject) => {
        getAuth().onIdTokenChanged((user) => {
            if (!user) {
                resolve(undefined);
            } else {
                resolve(user);
            }
        })
    }).then((uid) => uid).catch((e) => {
        throw (e);
    });

}

export const runAsync = (func) => {
    func();
};

export const formatProjectName = (projectName) => projectName.replaceAll("-", " ").trim();

export const filterDrawerListByDocument = (list) => list.filter((_drawerItem) => _drawerItem.document);

export const filterDrawerListByForm = (list) => list.filter((_drawerItem) => _drawerItem.form);

export const isDemoAccount = (_email) => firebaseDemos.indexOf(_email) !== -1;

export const isAdminAccount = (_email) => firebaseAdmins.indexOf(_email) !== -1;

export const isPaidAccount = (_email) => !isDemoAccount(_email);

export const getLanguageName = (langCode) => languages[langCode];

export const getLanguages = () => Object.getOwnPropertyNames(languages).map((langCode) => ({ code: langCode, name: getLanguageName(langCode) }));

export const getLanguageCode = (langName) => Object.getOwnPropertyNames(languages).find((langCode) => getLanguageName(langCode) === langName);