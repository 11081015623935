import { useEffect, useState } from "react";
import useAuth from "../../hooks/AuthUser";
import useRegisterPurchase from "../../hooks/firestore/RegisterPurchase";

export default function PaidUserComponent({ children }) {

    const { isAuthenticated, user } = useAuth();
    const { fetchRegisterPurchase } = useRegisterPurchase();
    const [registerPurchase, setRegisterPurchase] = useState({ vertification: false });

    useEffect(() => {
        fetchRegisterPurchase().then((_registerPurchase) =>

            setRegisterPurchase(_registerPurchase)
        );

    }, [isAuthenticated, user]);

    return (
        isAuthenticated && registerPurchase.vertification ? children : null
    );
}