import React from "react";
import useAuth from "../../hooks/AuthUser";

export default function ProtectedComponent({ children }) {

    const { isAuthenticated } = useAuth();

    return (
        isAuthenticated ? children : null
    );
}