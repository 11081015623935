
import { doc, getDoc, setDoc } from '@firebase/firestore';
import * as React from 'react';
import { FIRESTORE_COLLECTION_NAME } from '../../consts';
import { db } from '../../firebase';
import useAuthUser from '../AuthUser';
import useSnackbar from '../Snackbar';



export default function useProjects() {
    const [snackbar, setSnackbar] = useSnackbar();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { user } = useAuthUser();

    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }

    var doFetchProjects = async (userId) => {
        _setUiStream("start_loading");
        return getDoc(doc(db, FIRESTORE_COLLECTION_NAME, userId)).then((_doc) => _doc.exists() ? (_doc.data()["projects"] ?? []) : [])
            .catch((error) => setSnackbar("error", error))
            .finally(() => _setUiStream("stop_loading"));
    }

    var doFindProject = (projectName, projects) => {
        return projects.find((project) => projectName.toLowerCase() === project.name.toLowerCase());
    }

    var doUpdateProject = async (userId, projects) => {
        _setUiStream("start_loading");
        return setDoc(doc(db, FIRESTORE_COLLECTION_NAME, userId), { projects }, { merge: true }).catch((error) => setSnackbar("error", error))
            .finally(() => _setUiStream("stop_loading"));
    }


    return {
        fetchProjects: async (userId = user.uid) => {
            return doFetchProjects(userId);
        },

        updateProjects: async (userId = user.uid, projects = []) => {
            return doUpdateProject(userId, projects);
        },
        fetchProject: async (projectName, userId = user.uid) => {
            var projects = await doFetchProjects(userId);
            return doFindProject(projectName, projects);
        },
        updatePropertyId: async (propertyId, projectName, userId = user.uid) => {
            var projects = await doFetchProjects(userId);
            var project = doFindProject(projectName, projects);
            project["propertyId"] = propertyId;
            return doUpdateProject(userId, projects);
        },
        UiStream,
        components: [snackbar]
    };
}

/*

*/

/*
                    v */