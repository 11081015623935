import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function AppRatingDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >App Rating</Typography>
                    <Divider />
                    App Rating will ask user to rate the app via an dialog. The default value is 60 second. So It will ask the user to rate app after 60 second of app launch. If user reject the dialog, It will ask the same question after the Delay Day days again.
                    <br />
                    <br />
                    <i>Delay Time: The unit of the delay time is second.</i>
                    <br />
                    <i>Delay Day: The unit of the delay day is day.</i>
                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1MkbNpenKh4kii_qNYVeQuifzQjiay3oA/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the app rating image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

