import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import TranslationTextField from "../../../TranslationTextField";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function AppBarForm(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [active, setActive] = useState(true);
    const [defaultTitle, setDefaultTitle] = useState("Webvify");
    const [keepDefault, setKeepDefault] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [foregroundColor, setForegroundColor] = useState("#ffffff");
    const [elevation, setElevation] = useState(0);
    const [centerTitle, setCenterTitle] = useState(true);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.app_bar) {
            setActive(remoteConfig.app_bar.active);
            setDefaultTitle(remoteConfig.app_bar.default_title);
            setKeepDefault(remoteConfig.app_bar.keep_default);
            setBackgroundColor(remoteConfig.app_bar.background_color);
            setForegroundColor(remoteConfig.app_bar.foreground_color);
            setElevation(remoteConfig.app_bar.elevation);
            setCenterTitle(remoteConfig.app_bar.center_title);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.app_bar.active = active;
        config.app_bar.default_title = defaultTitle;
        config.app_bar.keep_default = keepDefault;
        config.app_bar.background_color = backgroundColor;
        config.app_bar.foreground_color = foregroundColor;
        config.app_bar.elevation = elevation;
        config.app_bar.center_title = centerTitle;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={keepDefault} onChange={(e) => setKeepDefault(e.target.checked)} name="keep_default" />
                        }
                        label="Keep Default" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={centerTitle} onChange={(e) => setCenterTitle(e.target.checked)} name="center_title" />
                        }
                        label="Center Title" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <TranslationTextField
                        fullWidth
                        helperText={"This is app bar title. You can show active website's page title by disabling the 'keep default' switch."}
                        required
                        remoteConfig={remoteConfig}
                        label={"Default Title"}
                        value={defaultTitle}
                        onChange={(value) => setDefaultTitle(value)}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        margin="normal"
                        fullWidth
                        name="background_color"
                        color='primary'
                        helperText={"Background color of the app bar"}
                        label="Background Color"
                        value={backgroundColor}
                        type="color"
                        id="background_color"
                        onChange={(e) => setBackgroundColor(e.target.value)}
                        autoComplete="background_color"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        helperText={"Icon and text color of the app bar"}
                        name="foreground_color"
                        color='primary'
                        label="Foreground Color"
                        value={foregroundColor}
                        type="color"
                        id="foreground_color"
                        onChange={(e) => setForegroundColor(e.target.value)}
                        autoComplete="foreground_color"
                    />
                </Grid>


            </Grid>
        </ProjectFormTemplate >
    );
}

/*<Grid item xs={12}>
<TextField
    margin="normal"
    error={errors?.elevation}
    helperText={errors?.elevation?.message ?? "Elevation of the app bar. 0(no elevation) is recomended"}
    required
    fullWidth
    name="elevation"
    color='primary'
    label="Elevation"
    value={elevation}
    {...register("elevation", { min: { value: 0, message: "This fields must more than zero" } })}
    type="number"
    id="elevation"
    onChange={(e) => setElevation(e.target.value > 100 ? 100 : e.target.value)}
    autoComplete="elevation"
/>
</Grid> */