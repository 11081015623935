import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function LoginAutoFillDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Login Autofil</Typography>
                    <Divider />
                    Login Autofill feature will autofil the login form inputs after login once. In this way, users won't have to rewrite their username and passwords again and again.
                    <br />
                    <br />
                    To use this feature, you need to activate the feature an enter the value of the username and password input names in the form.
                    <br />
                    <br />
                    To explain it better. Let's give an example. For example, let's be your html login form is like below.
                    <br />
                    <br />
                    <code >
                        &lt;form&gt;
                        <br />
                        &nbsp;&nbsp; &lt;input name="<b className="text-primary">email</b>" ... /&gt;
                        <br />
                        &nbsp;&nbsp; &lt;input name="<b className="text-primary">password</b>" ... /&gt;
                        <br />
                        &lt;/form&gt;
                    </code>
                    <br /> <br />
                    In such a case:   <br />
                    <b>Login Form Username Name:</b> email  <br />
                    <b>Login Form Password Name:</b> password
                    <br />
                    <br />
                    Futher, you can check <a rel="noreferrer" href="https://drive.google.com/file/d/11b3fBSHLVrmmDCMhEMgN3A2ZO8o_Yo_z/view?usp=sharing" target={"_blank"} className="text-primary">this video</a> to see how it works.
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

