import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function AppRatingForm(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();


    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);


    const [active, setActive] = useState(true);
    const [delayTime, setDelayTime] = useState(60);





    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.app_rating) {
            setActive(remoteConfig.app_rating.active);
            setDelayTime(remoteConfig.app_rating.delay_time);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.app_rating.active = active;
        config.app_rating.delay_time = delayTime * 1;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        error={errors?.delayTime}
                        helperText={errors?.delayTime?.message ?? "60 is one minute. App Rating dialog will appear in the app after that second. If the user reject to rate the app, it will ask the user to rate again after 7 days"}
                        fullWidth
                        autoFocus
                        name="delay_time"
                        color='primary'
                        label="Delay Time"
                        value={delayTime}
                        {...register("delayTime", { min: { value: 0, message: "This fields must more than zero" } })}
                        type="number"
                        id="delay_time"
                        onChange={(e) => setDelayTime(e.target.value)}
                        autoComplete="delay_time"
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}