import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function BottomNavigationBarDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>

                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Bottom Navigation Bar</Typography>
                    <Divider />
                    Bottom Navigation Bar is nice component which stands on the bottom of the screen. It contains the dynamic buttons you created in it. Via this button, you can redirect the users to the selected page, or run javascript code depends on the button type.
                    <br />
                    <br />
                    <b>Background Color: </b> Background color of the navigation bar. It can be your website background color. <br />
                    <b>Style No: </b> There is multiple styles for the navigation bar. You can check them  in this <a rel="noreferrer" href="https://pub.dev/packages/persistent_bottom_nav_bar" target={"_blank"} className="text-primary" >link</a> <br />
                    <b>Initial Button: </b> The button will be selected initially. If initial page of the app also have an button on the navigation, you should select it as initially.<br />
                    <b>Hide on This URL: </b> Bottom navigation bar will be invisible at these urls. You may want to add the login and register page urls to this list.<br />
                    <b>Bottom Bar Buttons: </b> This contains the buttons of the navigation bar. You can add the buttons you want to this list. By clicking on the buttons, you can see an popup screen to edit button's features. <br />

                    <br />
                    <br />
                    <b>Note 1:</b> To select icon for the buttons, you will see the link below the icon selection component.<br />
                    <b>Note 2:</b> You can select the order of the buttons in this version, you will be able to do it on the next versions. For now, you need to delete, and create it in order. Sorry about that.
                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/12ONOclgzeK03v7-h_IYc6mfDQCwZZrzO/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the bottom navigation bar image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

