import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function LoginAutoFillForm(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    const [active, setActive] = useState(true);
    const [usernameHTMLInputName, setUsernameHTMLInputName] = useState("email");
    const [passwordHTMLInputName, setPasswordHTMLInputName] = useState("password");



    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.login_auto_fill) {
            setActive(remoteConfig.login_auto_fill.active);
            setUsernameHTMLInputName(remoteConfig.login_auto_fill.username_htmlinput_name);
            setPasswordHTMLInputName(remoteConfig.login_auto_fill.password_htmlinput_name);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.login_auto_fill.active = active;
        remoteConfig.login_auto_fill.username_htmlinput_name = usernameHTMLInputName;
        remoteConfig.login_auto_fill.password_htmlinput_name = passwordHTMLInputName;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        autoFocus
                        fullWidth
                        error={errors?.usernameHTMLInputName}
                        helperText={errors?.usernameHTMLInputName?.message ?? "Type username/email/phone input name attribute of the website's login form"}
                        name="username_htmlinput_name"
                        color='primary'
                        label="Login Form Username Name"
                        value={usernameHTMLInputName}
                        {...register("usernameHTMLInputName", { required: 'This field is required' })}
                        type="text"
                        id="username_htmlinput_name"
                        onChange={(e) => setUsernameHTMLInputName(e.target.value)}
                        autoComplete="username_htmlinput_name"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        error={errors?.passwordHTMLInputName}
                        helperText={errors?.passwordHTMLInputName?.message ?? "Type password input name attribute of the website's login form."}
                        name="password_htmlinput_name"
                        color='primary'
                        label="Login Form Password Name"
                        value={passwordHTMLInputName}
                        {...register("passwordHTMLInputName", { required: 'This field is required' })}
                        type="text"
                        id="username_htmlinput_name"
                        onChange={(e) => setPasswordHTMLInputName(e.target.value)}
                        autoComplete="username_htmlinput_name"
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}