import { useEffect, useState } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import useAuth from '../../hooks/AuthUser';
import useRegisterPurchase from '../../hooks/firestore/RegisterPurchase';

export const PaidUserPage = ({ children }) => {
    const navigate = useNavigate();

    const { isAuthenticated, user } = useAuth();
    const [registerPurchase, setRegisterPurchase] = useState({ vertification: false });

    const { fetchRegisterPurchase } = useRegisterPurchase();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/signin");
        }

        fetchRegisterPurchase().then((_registerPurchase) => {
            if (!_registerPurchase.vertification) {
                navigate("/projects");
            }
            setRegisterPurchase(_registerPurchase);
        });

    }, [isAuthenticated, user, navigate]);



    return (
        <div>
            {isAuthenticated && registerPurchase.vertification && children}
        </div>
    );
};