import { Grid, ThemeProvider, Typography } from "@mui/material";
import { darkTheme } from "../theme";
import FormTemplate from "../components/FormTemplate";
import { Container } from "@mui/system";
import Header from "../components/Header";
import { Link } from "react-router-dom";


const articles = [
    { title: "How to check the notification analytics?", link: "/notifications-analytics" },
    { title: "How to push targeted notification?", link: "/targeted-notification" },
    { title: "Do social links work on the app?", link: "/" },
    { title: "How to hide some components in the app?", link: "/" }
];

export default function FAQPage() {
    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Header breadcrumbList={[{ name: "Projects", link: "/projects" }, { name: "Frequently Asked Questions", link: "/notifications-analytics" }]} ></Header>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >
                    <Grid container px={"8px"} py={"16px"}>
                        <Grid item xs={12}>
                            <Grid container justifyContent={"center"}>
                                <b style={{ "fontSize": "20px" }}>Frequently Asked Questions</b>
                            </Grid>

                            {
                                articles.map((article) => (<Grid container justifyContent={"center"} mt={1}><Typography color={"ButtonText"} variant="body" ><Link to={article.link}  > {article.title} </Link> <br /></Typography> <br /></Grid>))
                            }

                        </Grid>
                    </Grid>

                </Container>
            </FormTemplate >
        </ThemeProvider >
    );
}


