import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider } from '@mui/system';
import { lightTheme } from '../theme';

export default function TryDemoAppDialog(props) {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => { setOpen(props.open); }, [props.open]);
    return (
        <div>
            <ThemeProvider theme={lightTheme}>


                <Dialog open={open}>
                    <DialogTitle>Download Webvify App and Manage it on Fly</DialogTitle>
                    <DialogContent>
                        <p>
                            Hey, you can download the webvify app, and manage its features such as initial link, push notification, bottom navigation bar, themes, onboarding screen and other features through Admin Panel's demo account.
                        </p>

                        <p style={{ color: "red" }}>
                            <b>Important: </b> Do not hesitate to change the initial link of the webvify app to your website's link to see how it gonna work.
                        </p>

                        <p style={{ color: "orange" }}>
                            <b>Notice: </b> Other users may overwrite your changes. Because the account is used in paralels among multiple users.
                        </p>

                        <p>
                            TestFlight for iOS: <a className='text-primary' target={"_blank"} href="https://testflight.apple.com/join/SzrHPpRV">download</a>
                        </p>
                        <p>
                            Android link: <a className='text-primary' target={"_blank"} href="https://play.google.com/store/apps/details?id=com.webvify.app">download</a>
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onCancel}>Close</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div >
    );
}
