import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function JavascriptAndCSSDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >JavaScript and CSS</Typography>
                    <Divider />
                    You can inject javascript and css to the app by URL. Through javascript or CSS, you can custimize your website to the spesific to the app users. For example, you may want to hide some component of your website for the app users.
                    <br />
                    <br />
                    <b>My Advices:</b> I am always hiding footer section of the website inside the app. Because normally apps does not have footer section in it. So hiding footer section makes app more professional looks. That's an example, you can do whatever you want which is possible with javascript and CSS.
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

