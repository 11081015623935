// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
import { firebaseConfig } from "./configuration";
import { getStorage } from "firebase/storage";


// Initialize Firebase
var app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
getAnalytics(app);

