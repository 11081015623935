import { Grid, ThemeProvider, Typography } from "@mui/material";
import { darkTheme } from "../theme";
import FormTemplate from "../components/FormTemplate";
import { Container } from "@mui/system";
import Header from "../components/Header";




export default function SourceCodeDocument() {
    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Header breadcrumbList={[{ name: "Projects", link: "/projects" }, { name: "Source Code", link: "/source-code" }]} ></Header>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >
                    <Grid container px={"8px"} py={"16px"}>
                        <Grid item>
                            <Grid container justifyContent={"center"}>
                                <b style={{ "fontSize": "20px" }}>Source Code</b>
                            </Grid>
                            You can access Webvify Admin Panel to update your app when you need. However, I will share with you also the source code and, its installation tutorial video. Through the video, you will be able to install the admin panel to your <b>local machine</b> when you need.
                            <br />
                            <br />
                            No need to install the admin panel to the <b>Global Firebase Hosting & Functions</b> because it is paid and you won't be need to access it all time. So Installing it to your local will be enough
                            <br />
                            <br />
                            <b>Requirements</b>
                            <br />
                            The only requirements is NodeJS. You can download it via the below link. Please download LTS(v16) version.
                            <br />
                            <a href="https://nodejs.org/en/download/" target={"_blank"} className="text-primary" rel="noreferrer">NodeJS link</a>
                            <br />
                            <br />
                            After NodeJS is installed, you should run the below code on the terminal to install firebase-tools which you will need on install process.
                            <br />
                            <br />
                            Open your terminal, and run below code.
                            <br />
                            <code>npm install -g firebase-tools</code>
                            <br />
                            <br />
                            <br />
                            <b>Source Code & Installation Tutorial Video</b>
                            <br />
                            So you are ready to download source code & jump in the installation video tutorial!
                            <p>
                                <Typography color={"orangered"}>
                                    <b><b>!Important: Please use the app's connected firebase project for the installation, do not use a fresh firebase project.</b></b>
                                </Typography>
                            </p>
                            1. Download Panel Source Code: <a href="https://drive.google.com/file/d/1xNC_5-zR9jA7wbXflCDjMwlYNl20V74R/view?usp=sharing" target={"_blank"} className="text-primary" rel="noreferrer"><b>Tap to Download</b></a>
                            <br />
                            2. Watch the Installation Video Tutorial: <a href="https://drive.google.com/file/d/1fd5EfAKBpwhGYu_7oCfxZgASfHCVdUAi/view?usp=sharing" target={"_blank"} className="text-primary" rel="noreferrer"><b>Tap to Watch</b></a>
                            <br />

                            <p>
                                <b>Note:</b> You have to wait 10-15 minutes after firebase configuration is done. Because firebase changes needs sometime to perform.
                            </p>
                            <p>
                                <b>Note:</b> The name of the program which I am using on the video is <a rel="noreferrer" href="https://code.visualstudio.com/" target={"_blank"} className="text-primary" >visual studio code</a>
                            </p>
                            <p>
                                <b>Note:</b> I have showed how to install it locally on the macos, but It will have the same process on the other OS. Don't worry about that.
                            </p>
                        </Grid>
                    </Grid>

                </Container>
            </FormTemplate >
        </ThemeProvider >
    );
}


