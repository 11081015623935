import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProjectFormTemplate from "./ProjectFormTemplate";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import CachedIcon from '@mui/icons-material/Cached';
import useNotification from "../../../../hooks/backend/Notification";


export default function PushNotificationForm() {
    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();

    const { pushNotification, UiStream, components } = useNotification();
    const [loading, setLoading] = useState(false);

    // form
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);



    const handleSaveButton = () => {
        var notification = { title, body, imageUrl, link };
        setTimeout(() => pushNotification(notification), 500);
    };


    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            saveButtonText="Push"
            saveButtonIcon={<SendToMobileIcon />}
            footer={
                <Grid item>
                    <Button
                        onClick={() => {
                            setTitle("");
                            setBody("");
                            setImageUrl("");
                            setLink("");
                            clearErrors();

                        }}
                        startIcon={<CachedIcon />}
                        variant="contained"
                        color="warning"
                    >
                        Clear Form
                    </Button>
                </Grid>
            }
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="title"
                        error={errors?.title}
                        helperText={errors?.title?.message}
                        color='primary'
                        label="Title"
                        value={title}
                        required
                        {...register("title", { required: 'This field is required' })}
                        type="text"
                        id="title"
                        autoFocus
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="body"
                        error={errors?.body}
                        helperText={errors?.body?.message}
                        color='primary'
                        label="Body"
                        value={body}
                        required
                        {...register("body", { required: 'This field is required' })}
                        type="text"
                        id="body"
                        onChange={(e) => setBody(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="image_url"
                        color='primary'
                        label="Image Url"
                        value={imageUrl}
                        type="url"
                        id="javascript_url"
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="link"
                        color='primary'
                        label="Link"
                        value={link}
                        type="text"
                        id="link"
                        onChange={(e) => setLink(e.target.value)}
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate >


    );
}
