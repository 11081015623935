import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ArticleIcon from '@mui/icons-material/Article';
import { components, DrawerMenu, drawerMenuList, features, settings } from './DrawerMenuList';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { lightTheme } from '../../../theme';
import { filterDrawerListByForm, formatProjectName } from '../../../helpers';
import AccountButton from '../../AccountButton';
import AppBreadcrumbs from '../../AppBreadcrumbs';
import Copyright from '../../Copyright';
import { logoColorful } from '../../../consts';


const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);




export default function ProjectPage(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const { project } = useParams();




    const [open, setOpen] = React.useState(true);
    const [selectedDrawerMenu, setSelectedDrawerMenu] = React.useState({});

    React.useEffect(() => {
        const _drawerMenuPath = location.pathname.replace(project, ":project");
        var _selectedDrawerMenu = drawerMenuList.find((_drawerMenu) => (_drawerMenu.form.path === _drawerMenuPath));
        _selectedDrawerMenu.isDashboard = (_selectedDrawerMenu.name === "Dashboard");
        setSelectedDrawerMenu(_selectedDrawerMenu);
    }, [location, project]);



    const toggleDrawer = () => {
        setOpen(!open);
    };

    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setOpen(false);
        }
    }
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);



    return (
        <ThemeProvider theme={lightTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {formatProjectName(project)}
                        </Typography>


                        <AccountButton />
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} title='dd'>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}

                    >
                        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "30px" }}>
                            <img src={require(`../../../assets/${logoColorful}`)} width="165" alt='app logo' />
                        </Box>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav" >
                        <DrawerMenu list={filterDrawerListByForm(features)} selectedIndex={selectedDrawerMenu.index} getPath={(_drawerItem) => _drawerItem.form.path.replace(":project", project)} />
                        <Divider />
                        <DrawerMenu list={filterDrawerListByForm(settings)} selectedIndex={selectedDrawerMenu.index} getPath={(_drawerItem) => _drawerItem.form.path.replace(":project", project)} />
                        <Divider />
                        <DrawerMenu list={filterDrawerListByForm(components)} selectedIndex={selectedDrawerMenu.index} getPath={(_drawerItem) => _drawerItem.form.path.replace(":project", project)} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Grid container alignContent={"center"} justifyContent={"center"}
                        sx={{ mt: 4, mb: 4 }}>
                        <Grid item xs={12}>
                            <Grid container alignContent={"center"} justifyContent={"center"}>
                                <Grid item xs={12} sm={selectedDrawerMenu.fullScreen ? 12 : 9} xl={selectedDrawerMenu.fullScreen ? 12 : 6} sx={{ px: 3 }}>
                                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                        <Grid item>
                                            <AppBreadcrumbs breadcrumbList={[{ name: "Projects", link: "/projects" }, { name: formatProjectName(project) }]} />
                                        </Grid>
                                        <Grid item >
                                            <Grid container justifyContent={"end"} alignItems={"center"} >

                                                <IconButton onClick={() => window.location.reload()} color="primary">
                                                    <RefreshIcon />
                                                </IconButton>


                                                {
                                                    selectedDrawerMenu.document ?
                                                        <Grid item ml={1}>

                                                            <Button id={"project-document"} variant="outlined" size='small' onClick={() => {
                                                                navigate(selectedDrawerMenu.document?.path.replace(":project", project));
                                                            }} endIcon={<ArticleIcon color="primary" />}>
                                                                Document
                                                            </Button>
                                                        </Grid> : null
                                                }
                                            </Grid>
                                        </Grid>



                                    </Grid>
                                    <Grid item xs={12}>
                                        <Outlet />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider >
    );
}
