import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import {
    Link,
} from "react-router-dom";
import FormTemplate from '../components/FormTemplate';
import useSnackbar from '../hooks/Snackbar';
import useAuth from '../hooks/AuthUser';
import { FormControl, FormHelperText } from '@mui/material';
import useRegisterPurchase from '../hooks/firestore/RegisterPurchase';
import { reviewMode } from '../configuration';


export default function SignUpPage() {

    const { onLogin } = useAuth();
    const { setRegisterPurchase } = useRegisterPurchase();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [purchaseCode, setPurchaseCode] = useState('');

    const [snackbar, openSnackbar] = useSnackbar();

    const handleSubmit = (event) => {
        event.preventDefault();
        const authentication = getAuth();
        createUserWithEmailAndPassword(authentication, email, password)
            .then((result) => {
                setRegisterPurchase("codecanyon", purchaseCode, result.user.uid)
                onLogin(result.user);
            })
            .catch((error) =>
                openSnackbar("error", error.message)
            );
    };

    return (
        <FormTemplate title="Sign Up Form">
            {snackbar}
            <Grid container justifyContent={'center'}>
                <Grid item container xs={12} sm={8} md={6}>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            color='secondary'
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            color='secondary'
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                    </Grid>

                    {
                        !reviewMode && (<Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="purchase_code"
                                    color='secondary'
                                    label="Codecanyon Purchase Code"
                                    type="text"
                                    id="purchase_code"
                                    value={purchaseCode}
                                    onChange={(e) => setPurchaseCode(e.target.value)}
                                />
                                <FormHelperText>
                                    <a rel="noreferrer" href='https://help.market.envato.com/hc/en-us/articles/202822600-Where-Is-My-Purchase-Code-' target={'_blank'}>Click here to see how to get <b>purchase code</b></a>
                                </FormHelperText>
                            </FormControl>

                        </Grid>)
                    }

                    <Grid item xs={12}>
                        <Button
                            onClick={handleSubmit}
                            fullWidth
                            variant="contained"
                            color='secondary'
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="body" >
                            <Link to="/signin"  > Already have account? </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </FormTemplate >
    );
}

/*

*/

