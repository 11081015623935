import { Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function PreferencesDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Preferences</Typography>
                    <Divider />
                    <b>Add Firebase Token To Initial Url: </b> This will add the firebase token end of the initial url as parameters.
                    <br />
                    Let's say your domain is <b>https://yourdomain.com</b>
                    <br />
                    By enabling this feature, the initial url will be <b>https://yourdomain.com?firebase_token={"{FIREBASE_TOKEN}"}</b>
                    <br />
                    You can use the firebase token to send targeted notification to the user. If you want to know how to push targeted notification, please check <Link className="text-primary" to="/targeted-notification" >this article.</Link>
                    <br />
                    <br />
                    <b >Pull to Refresh:</b> This will enable Pull to Refresh feature in the app.
                    <br />
                    <br />
                    <b>App Upgrader:</b>This will enable  App Upgrader feature in the app. App upgrader will ask the user to upgrade the app if new version is available on the stores.
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1QOn8lWk9lnCnWUBLvtPYVSWOkieuDGMv/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the app upgrader image!</a>
                    <br />
                    <br />

                    <b>Vertical Orientations: </b> By enabling Vertical Orientations, Portrait Orientation lock will be active. So when you turn the device horizontally, nothing will be changed.
                    <br />
                    <br />
                    <b>Location Permission:</b> It will ask the user location permission on app launch.
                    <br />
                    <br />
                    <b>Camera Permission:</b> It will ask the user camera permission on app launch.
                    <br />
                    <br />
                    <b>Storage Permission:</b> It will ask the user storage permission on app launch.
                    <br />
                    <br />
                    <b>Support Zoom:</b> Set to false if the WebView should not support zooming using its on-screen zoom controls
                    <br />
                    <br />
                    <b>Vertical Scroll Bar Enabled:</b> Define whether the vertical scrollbar should be drawn or not.
                    <br />
                    <br />
                    <b>Horizontal Scroll Bar Enabled:</b> Define whether the horizontal scrollbar should be drawn or not.
                    <br />
                    <br />
                    <b>Disable Vertical Scroll:</b> Set to true to disable vertical scroll.
                    <br />
                    <br />
                    <b>Disable Horizontal Scroll:</b>  Set to true to disable horizontal scroll.
                    <br />
                    <br />
                    <b>Media Playback Requires User Gesture:</b>  If an media(audio, video) is exists on the web page, this will start to play it automatically after page is loaded
                    <br />
                    <br />
                    <b>Disable Context Menu:</b>  When you long press the text on the screen, the context menu will come up and show some buttons on it like; copy, share, translate... This will disable the context menu.
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1jYYcL-ePE8RwXQuDB-JJlUPXVchfZ2Xq/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the context menu image!</a>
                    <br />
                    <br />
                    <b>IOS Allow Link Preview:</b>  Set to true to allow that pressing on a link displays a preview of the destination for the link.
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1duclVrDKqc84O8nAvKqfpOdo8KOqUm9k/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the link preview image!</a>
                    <br />
                    <br />
                    <b>IOS Disable Long Press Context Menu On Links:</b>  Set to true to disable the context menu (copy, select, etc.) that is shown when the user emits a long press event on a HTML link. This is implemented using also JavaScript, so it must be enabled or it won't work.
                    <br />
                    <br />
                    <b>Page Not Found Error Screen:</b>  After 404 errors is happened on webview, the App will show the users nice designed native 404 error screen.
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/15yVzlU0WqbVKxNy34apzGMW9XmWflM9N/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the not found page image!</a>
                    <br />
                    <br />
                    <b>Error Screen:</b>  After any errors is happened on webview, the App will show the users nice designed native  error screen.
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1jd1dUE0ffpS8C6XuCZLHX4pxfE7lCVj9/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the error page image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate >
    );
}

