import { Breadcrumbs, Grid, Typography } from "@mui/material";


import { Link } from 'react-router-dom';


export default function AppBreadcrumbs(props) {

    const { breadcrumbList } = props;
    return (
        <Grid container sx={{ px: 0 }}>
            <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                    {breadcrumbList?.map((breadcrumbItem, index) => {
                        return breadcrumbList.length - 1 > index ?
                            (<Typography key={index} variant="body" fontWeight={"bold"} ><Link to={breadcrumbItem.link} >
                                {breadcrumbItem.name}
                            </Link> </Typography>) :
                            (<Typography key={index} variant="body" >{breadcrumbItem.name}</Typography>)
                    })}
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
}



