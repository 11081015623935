import {
  Routes,
  Route,
} from 'react-router-dom'
import React from 'react';
import SignInPage from './pages/SignIn';
import ForgotPasswordPage from './pages/ForgotPassword';
import ProjectsPage from './pages/Projects';

import { ProtectedPage } from './components/Auth/ProtectedPage';
import { AnonymousPage } from './components/Auth/AnonymousPage';
import { filterDrawerListByDocument, filterDrawerListByForm } from './helpers';
import { PaidUserPage } from './components/Auth/PaidUserPage';
import { ProjectDispatcher } from './components/project/ProjectDispatcher';

// v1
import * as V1ProjectPage from './components/project/v1/Project';
import * as v1DrawerMenu from './components/project/v1/DrawerMenuList';
import * as v1Document from './components/project/v1/Document';
// v2
import * as V2ProjectPage from './components/project/v2/Project';
import * as v2DrawerMenu from './components/project/v2/DrawerMenuList';
import * as v2Document from './components/project/v2/Document';
import SourceCodeDocument from './pages/SourceCode';
import NotificationsAnalyticsDocument from './faq/NotificationsAnalytics';
import TargetedNotificationDocument from './faq/TargetedNotification';
import SignUpPage from './pages/SignUp';
import FAQPage from './pages/FAQ';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import PortfolioPage from './pages/fiverr/Portfolio';
import AdminPanelPage from './pages/fiverr/AdminPanel';
import BlakelyChickenAppPage from './pages/fiverr/BlakelyChickenApp';




// Add template for SignIn and ForgotPAssword Form
function App() {
  return (
    <div className="App" >
      <Routes>
        <Route index element={<ProtectedPage><ProjectsPage /></ProtectedPage>} />
        <Route exact path='/projects' element={<ProtectedPage><ProjectsPage /></ProtectedPage>} />

        <Route exact path='/source-code' element={<PaidUserPage><SourceCodeDocument /></PaidUserPage>} />
        <Route exact path='/notifications-analytics' element={<NotificationsAnalyticsDocument />} />
        <Route exact path='/targeted-notification' element={<TargetedNotificationDocument />} />
        <Route exact path='/frequently-asked-questions' element={<FAQPage />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />

        <Route exact path='/portfolio' element={<PortfolioPage />} />
        <Route exact path='/admin-panel' element={<AdminPanelPage />} />
        <Route exact path='/blakely-chicken-app' element={<BlakelyChickenAppPage />} />

        <Route exact path='/signin' element={<AnonymousPage><SignInPage /> </AnonymousPage>} />
        <Route exact path='/signup' element={<AnonymousPage><SignUpPage /> </AnonymousPage>} />
        <Route path='/forgot-password/:param' element={<ForgotPasswordPage />} />
        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route exact path="/project/:project/dispatcher" element={<ProtectedPage><ProjectDispatcher /></ProtectedPage>} />
        {
          [
            { project: <V1ProjectPage.default />, document: <v1Document.default />, drawerList: v1DrawerMenu.drawerMenuList, version: 'v1' },
            { project: <V2ProjectPage.default />, document: <v2Document.default />, drawerList: v2DrawerMenu.drawerMenuList, version: 'v2' },
          ].map(({ project, document, drawerList, version }, index) => (
            <React.Fragment key={index}>
              <Route exact path={`/${version}/project/:project`} element={<ProtectedPage> {project} </ProtectedPage>} >
                {
                  filterDrawerListByForm(drawerList).map(
                    (_drawerMenu, index) => (
                      <Route key={index} exact path={_drawerMenu.form.path} element={_drawerMenu.form.component} />
                    )
                  )
                }
              </Route>
              <Route exact path={`/${version}/document/:project`} element={document} >
                {
                  filterDrawerListByDocument(drawerList).map(
                    (_drawerMenu, index) => (
                      <Route key={index} exact path={_drawerMenu.document?.path} element={_drawerMenu.document?.component} />
                    )
                  )
                }
              </Route>
            </React.Fragment>
          ))
        }

        <Route path='*' element={<ProtectedPage><ProjectsPage /></ProtectedPage>} />
      </Routes>
    </div>
  );
}

export default App;



/*

*/
