import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function BannerAdsDialog({ open, bannerAds, onCancel, onSave }) {



    // form
    const [active, setActive] = React.useState(true);
    const [iOSAdId, setiOSAdId] = React.useState("");
    const [androidAdId, setAndroidAdId] = React.useState("");
    const [location, setLocation] = React.useState("bottom");


    React.useEffect(() => {
        if (bannerAds) {
            setActive(bannerAds.active);
            setiOSAdId(bannerAds.iOS_ad_id);
            setAndroidAdId(bannerAds.android_ad_id);
            setLocation(bannerAds.location);
        }
    }, [bannerAds]);

    return (
        <div>
            <Dialog open={open} onClose={onCancel}   >
                <DialogTitle>Banner Ads</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                                }
                                label="Active" className="text-primary" labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="ios_ad_id"
                                color='primary'
                                helperText={"App Open Ad iOS ID"}
                                label="iOS Ad ID"
                                autoFocus
                                value={iOSAdId}
                                type="text"
                                id="ios_ad_id"
                                onChange={(e) => setiOSAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="android_ad_id"
                                color='primary'
                                helperText={"App Open Ad Android ID"}
                                label="Android Ad ID"
                                autoFocus
                                value={androidAdId}
                                type="text"
                                id="android_ad_id"
                                onChange={(e) => setAndroidAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} x={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={location}
                                    label="Location"
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    <MenuItem value={"both"}>Both</MenuItem>
                                    <MenuItem value={"top"}>Top</MenuItem>
                                    <MenuItem value={"bottom"}>Bottom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={() => {
                        var ads = {
                            "active": active,
                            "iOS_ad_id": iOSAdId,
                            "android_ad_id": androidAdId,
                            "location": location
                        };
                        onSave(ads);
                    }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
