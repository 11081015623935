import { Divider, Grid, Typography } from "@mui/material";
import FirebasePanelLink from "../../../FirebasePanelLink";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function MobileAdsDocument() {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Mobile Ads</Typography>
                    <Divider />
                    Mobile Ads means AdMob. AdMob is an ads service to monetize from your app.

                    <br />
                    <br />
                    To activate the ads on your app, you have to acquire iOS and android ad ids from the AdMob website. After you take the ad ids, you need to add it to <code>assets/config/build-configuration.yaml</code> file in the source code, and recompile & submit the app again to the stores.
                    <br />
                    <br />
                    If it is already activated on the app but seems disabled on the admin panel, you just need to go <FirebasePanelLink /> {"->"} Remote Config {"->"} Configuration, then active the mobile ads. <br /> <a rel="noreferrer" target={"_blank"} href="https://drive.google.com/file/d/1-V3AN40ILPO1a4EzsRdo-J2Kul9XH8JZ/view?usp=sharing" className="text-primary">Please watch the video to see how to do it.</a>

                    <br />
                    <br />
                    Finally, that's it.




                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}