import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function OnBoardingScreenDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Onboarding Screen</Typography>
                    <Divider />
                    Onboarding screen will come up after splash screen to introduce the app to the user if it is enabled. If you enable both 'Active' and 'Show On Every Launch', The images will be showed on every app launch.

                    <br />
                    <br />
                    <b>Note:</b> The image's size should be (w:750  × h: 1334) and compressed

                    <br />
                    <br />
                    Futher, you can check <a rel="noreferrer" href="https://drive.google.com/file/d/1EAR6TulW9v3FKBzdk8nfT9JD5ckwgC4_/view?usp=sharing" target={"_blank"} className="text-primary">this video</a> to see how it works.
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}


