import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function QRCodeForm(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [active, setActive] = useState(true);
    const [redirectURL, setRedirectURL] = useState("");
    const [type, setType] = useState("");

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.qr_code) {
            setActive(remoteConfig.qr_code.active);
            setRedirectURL(remoteConfig.qr_code.redirect_url);
            setType(remoteConfig.qr_code.type);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.qr_code.active = active;
        config.qr_code.redirect_url = redirectURL;
        config.qr_code.type = type;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        error={errors?.redirectURL}
                        helperText={errors?.redirectURL?.message ?? "After a code is scanned on app side, it will redirect the users to the inputted url with adding the code as parameter. Do Not change {{QR_CODE}} parameter whether you select the type as QR Code or Barcode."}
                        required
                        fullWidth
                        id="redirectURL"
                        label="Redirect URL"
                        name="redirectURL"
                        value={redirectURL}
                        {...register("redirectURL", { required: 'This field is required' })}
                        type="url"
                        autoComplete="text"
                        autoFocus
                        onChange={(e) => setRedirectURL(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} x={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Type"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value={"qrcode"}>QR Code</MenuItem>
                            <MenuItem value={"barcode"}>Barcode</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}