import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function BottomControlBarForm(props) {
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);



    // form
    const [active, setActive] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [iconColor, setIconColor] = useState("#ffffff");

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.bottom_control_bar) {
            setActive(remoteConfig.bottom_control_bar.active);
            setBackgroundColor(remoteConfig.bottom_control_bar.background_color);
            setIconColor(remoteConfig.bottom_control_bar.icon_color);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.bottom_control_bar.active = active;
        config.bottom_control_bar.background_color = backgroundColor;
        config.bottom_control_bar.icon_color = iconColor;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="background_color"
                        color='primary'
                        label="Background Color"
                        value={backgroundColor}
                        type="color"
                        id="background_color"
                        onChange={(e) => setBackgroundColor(e.target.value)}
                        autoComplete="background_color"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="indicator_color"
                        color='primary'
                        label="Icon Color"
                        value={iconColor}
                        type="color"
                        id="indicator_color"
                        onChange={(e) => setIconColor(e.target.value)}
                        autoComplete="indicator_color"
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate>
    );
}