import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function QRCodeDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >QR Code</Typography>
                    <Divider />
                    QR Code feature will create an button on the right bottom side of the app. This button will open the camera to allow him/her to read a QR Code or Barcode depends on the type.
                    <br />
                    <br />
                    After code is readed, App will redirect the user to the "Redirect URL" with the parametered barcode. In this way, you can catch the parameter, and use it on back side.
                    <br />
                    <br />
                    For example, you may use it to filtre products, and list the filtred product on the screen. It depends on your needs.
                    <br />
                    <br />
                    <b>Note:</b> Do Not change the <b>{"qr_code={{QR_CODE}}"}</b> parameter whether you select the type as QR Code or Barcode.
                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1PSZe_FL9qRH3_k5Bp0ArOEsqrsNhHVPT/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the qr code button image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

