import { Box, Grid } from "@mui/material";



export default function ProjectDocumentTemplate(props) {
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    {props.children}
                    <br />

                </Grid>
            </Grid>
        </Box>
    );
}


//<a rel="noreferrer"  href="https://www.fiverr.com/omerbyrk?up_rollout=true" target={"_blank"} className="text-primary"><b>If you have an question, you can reach me on the fiverr!</b></a>

