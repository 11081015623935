import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid } from '@mui/material';
import { getLanguageCode, getLanguages } from '../../../../../helpers';

export default function AddLocaleDialog({ open, onCancel, onSave }) {

    const [selectedLanguage, setSelectedLanguage] = React.useState("English");

    return (
        <div>
            <Dialog open={open} onClose={onCancel} fullWidth  >
                <DialogTitle>Add Language</DialogTitle>
                <DialogContent>
                    <Grid container sx={{ mt: 1 }} height={"400px"}>
                        <Grid item xs={12}>
                            <Autocomplete
                                value={selectedLanguage}
                                onChange={(event, newValue) => {
                                    setSelectedLanguage(newValue);
                                }}
                                disablePortal
                                id="combo-box-demo"
                                options={getLanguages().map((lang) => lang.name)}
                                renderInput={(params) => <TextField {...params} label="Languages" />}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={() => {
                        onSave(getLanguageCode(selectedLanguage));
                    }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
