import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function InAppLinksDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >In App Links</Typography>
                    <Divider />
                    The links of the in app link list will open in the app even they are external links.
                    <br />
                    <br />
                    Let's say your domain name is https://yourdomain.com or https://yourdomain.com/login (does not matter)
                    <br />
                    <br />
                    The below links will open in the app. The first 4 links are already Internal Links, because they are belong to your domain.
                    <br />
                    <ul>
                        <li>https://yourdomain.com/***</li>
                        <li>https://www.yourdomain.com/***</li>
                        <li>http://yourdomain.com/***</li>
                        <li>http://www.yourdomain.com/***</li>
                        <li>In App Links</li>
                    </ul>
                    Futher, you can check <a rel="noreferrer" href="https://drive.google.com/file/d/1g5P3RNASNRqfzqjqrBlNndGX8dOAzqVx/view?usp=sharing" target={"_blank"} className="text-primary">this video</a> to see how it works.
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

