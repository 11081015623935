import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import ProjectFormTemplate from "./ProjectFormTemplate";
import ListFormControl from "../../../ListFormControl";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";



export default function OnBoardingScreenForm(props) {
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // forms
    const [active, setActive] = useState(true);
    const [showOnEveryLaunch, setShowOnEveryLaunch] = useState(true);
    const [imageURLs, setImageURLs] = useState([]);


    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.onboarding_screen) {
            setActive(remoteConfig.onboarding_screen.active);
            setShowOnEveryLaunch(remoteConfig.onboarding_screen.show_on_every_launch);
            setImageURLs(remoteConfig.onboarding_screen.image_urls);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.onboarding_screen.active = active;
        config.onboarding_screen.show_on_every_launch = showOnEveryLaunch;
        config.onboarding_screen.image_urls = imageURLs;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={showOnEveryLaunch} onChange={(e) => setShowOnEveryLaunch(e.target.checked)} name="active" />
                        }
                        label="Show on Every Launch" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ListFormControl
                        label="Image URL"
                        helperText="The images should be resized to (w:750  × h: 1334) and compressed. Enabling only 'Active' will show the images to the users when they launch the app first time. Enabling 
                        Both 'Active' and 'Show On Every Launch' will show the images every app launch."
                        list={imageURLs}
                        onAdd={(_argNewItem) => {
                            if (imageURLs) {
                                setImageURLs([...imageURLs, _argNewItem]);

                            } else {
                                setImageURLs([_argNewItem]);
                            }
                        }}
                        onDelete={(_argItem) => {
                            setImageURLs(imageURLs.filter(v => v !== _argItem));
                        }}
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate >
    );
}