import AppBarForm from "./forms/AppBarForm";
import AppRatingForm from "./forms/AppRatingForm";
import BottomControlBarForm from "./forms/BottomControlBarForm";
import BottomNavigationBarForm from "./forms/BottomNavigationBar/BottomNavigationBarForm";
import GeneralSettingsForm from "./forms/GeneralSettingsForm";
import InAppLinksForm from "./forms/InAppLinksForm";
import JavascriptAndCSSForm from "./forms/JavascriptAndCSSForm";
import LoadingScreenForm from "./forms/LoadingScreenForm";
import LoginAutoFillForm from "./forms/LoginAutoFillForm";
import OnBoardingScreenForm from "./forms/OnBoardingScreenForm";
import PreferencesForm from "./forms/PreferencesForm";
import PushNotificationForm from "./forms/PushNotificationForm";
import QRCodeForm from "./forms/QRCodeForm";
import StatusBarForm from "./forms/StatusBarForm";
import UserAgentForm from "./forms/UserAgentForm";
import SettingsIcon from '@mui/icons-material/Settings';
import JavascriptIcon from '@mui/icons-material/Javascript';
import LinkIcon from '@mui/icons-material/Link';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import LoginIcon from '@mui/icons-material/Login';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import TuneIcon from '@mui/icons-material/Tune';
import LoopIcon from '@mui/icons-material/Loop';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HttpIcon from '@mui/icons-material/Http';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import GeneralSettingsDocument from "./document/GeneralSettingsDocument";
import JavascriptAndCSSDocument from "./document/JavascriptAndCSSDocument";
import InAppLinksDocument from "./document/InAppLinksDocument";
import StatusBarDocument from "./document/StatusBarDocument";
import LoadingScreenDocument from "./document/LoadingScreenDocument";
import LoginAutoFillDocument from "./document/LoginAutoFillDocument";
import AppRatingDocument from "./document/AppRatingDocument";
import AppBarDocument from "./document/AppBarDocument";
import BottomControlBarDocument from "./document/BottomControlBarDocument";
import QRCodeDocument from "./document/QRCodeDocument";
import UserAgentDocument from "./document/UserAgentDocument";
import PreferencesDocument from "./document/PreferencesDocument";
import OnBoardingScreenDocument from "./document/OnBoardingScreenDocument";
import BottomNavigationBarDocument from "./document/BottomNavigationBarDocument";
import PushNotificationDocument from "./document/PushNotificationDocument";
import DashboardIcon from '@mui/icons-material/Dashboard';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import Dashboard from "../../../pages/Dashboard";

export const features = [
    {
        hidden: false,
        index: 0,
        name: "Dashboard",
        icon: <DashboardIcon />,
        fullScreen: true,
        form: {
            path: "/v1/project/:project/dashboard",
            component: <Dashboard />,
        },
    },
    {
        index: 1,
        name: "Push Notification",
        icon: <NotificationAddIcon />,
        form: {
            path: "/v1/project/:project/push-notification",
            component: <PushNotificationForm />,
        },
        document: {
            component: <PushNotificationDocument />,
            path: "/v1/document/:project/push-notification",
        },
    },

];

export const settings = [
    {
        index: 3,
        name: "General Settings",
        icon: <SettingsIcon />,
        form: {
            component: <GeneralSettingsForm />,
            path: "/v1/project/:project/general-settings",
        },
        document: {
            component: <GeneralSettingsDocument />,
            path: "/v1/document/:project/general-settings",
        },

    },
    {
        index: 4,
        name: "Preferences",
        icon: <TuneIcon />,
        form: {
            path: "/v1/project/:project/preferences",
            component: <PreferencesForm />,
        },
        document: {
            component: <PreferencesDocument />,
            path: "/v1/document/:project/preferences",
        },
    },
    {
        index: 5,
        name: "External Links",
        icon: <LinkIcon />,
        form: {
            path: "/v1/project/:project/in-app-links",
            component: <InAppLinksForm />,
        },
        document: {
            component: <InAppLinksDocument />,
            path: "/v1/document/:project/in-app-links",
        },
    },
    {
        index: 6,
        name: "Login Autofill",
        icon: <LoginIcon />,
        form: {
            path: "/v1/project/:project/login-autofill",
            component: <LoginAutoFillForm />,
        },
        document: {
            component: <LoginAutoFillDocument />,
            path: "/v1/document/:project/login-autofill",
        },
    },
    {
        index: 7,
        name: "User Agent",
        icon: <HttpIcon />,
        form: {
            path: "/v1/project/:project/user-agent",
            component: <UserAgentForm />,
        },
        document: {
            component: <UserAgentDocument />,
            path: "/v1/document/:project/user-agent",
        },
    },
    {
        index: 8,
        name: "Javascript/CSS",
        icon: <JavascriptIcon />,
        form: {
            path: "/v1/project/:project/javascript-css",
            component: <JavascriptAndCSSForm />,
        },
        document: {
            component: <JavascriptAndCSSDocument />,
            path: "/v1/document/:project/javascript-css",
        },
    },
];

export const components = [
    {
        index: 9,
        name: "Status Bar",
        icon: <SignalWifiStatusbar4BarIcon />,
        form: {
            path: "/v1/project/:project/status-bar",
            component: <StatusBarForm />,
        },
        document: {
            component: <StatusBarDocument />,
            path: "/v1/document/:project/status-bar",
        },
    },
    {
        index: 10,
        name: "App Bar",
        icon: <AdUnitsIcon />,
        form: {
            path: "/v1/project/:project/app-bar",
            component: <AppBarForm />,
        },
        document: {
            component: <AppBarDocument />,
            path: "/v1/document/:project/app-bar",
        },
    },
    {
        index: 11,
        name: "Onboarding Screen",
        icon: <AutoAwesomeIcon />,
        form: {
            path: "/v1/project/:project/onboarding-screen",
            component: <OnBoardingScreenForm />,
        },
        document: {
            component: <OnBoardingScreenDocument />,
            path: "/v1/document/:project/onboarding-screen",
        },
    },
    {
        index: 12,
        name: "Loading Screen",
        icon: <LoopIcon />,
        form: {
            path: "/v1/project/:project/loading-screen",
            component: <LoadingScreenForm />,
        },
        document: {
            component: <LoadingScreenDocument />,
            path: "/v1/document/:project/loading-screen",
        },
    },
    {
        index: 13,
        name: "App Rating",
        icon: <StarOutlineIcon />,
        form: {
            path: "/v1/project/:project/app-rating",
            component: <AppRatingForm />,
        },
        document: {
            component: <AppRatingDocument />,
            path: "/v1/document/:project/app-rating",
        },
    },
    {
        index: 14,
        name: "QR Code",
        icon: <QrCodeIcon />,
        form: {
            path: "/v1/project/:project/qr-code",
            component: <QRCodeForm />,
        },
        document: {
            component: <QRCodeDocument />,
            path: "/v1/document/:project/qr-code",
        },
    },

    {
        index: 15,
        name: "Bottom Navigation Bar",
        icon: <SmartButtonIcon />,
        form: {
            path: "/v1/project/:project/bottom-navigation-bar",
            component: <BottomNavigationBarForm />,
        },
        document: {
            component: <BottomNavigationBarDocument />,
            path: "/v1/document/:project/bottom-navigation-bar",
        },
    },
    {
        index: 16,
        name: "Bottom Control Bar",
        icon: <CompareArrowsIcon />,
        form: {
            path: "/v1/project/:project/bottom-control-bar",
            component: <BottomControlBarForm />,
        },
        document: {
            component: <BottomControlBarDocument />,
            path: "/v1/document/:project/bottom-control-bar",
        },
    },


];

export const drawerMenuList = [...features, ...settings, ...components];

export function DrawerMenu(props) {
    const navigate = useNavigate();

    const { list, selectedIndex, getPath } = props;

    return (
        <React.Fragment>
            {list.filter((_drawerItem) => !_drawerItem.hidden).map(_drawerItem => (
                <ListItemButton key={_drawerItem.index} onClick={() => {
                    navigate(getPath(_drawerItem));
                }} color={"primary"} selected={_drawerItem.index === selectedIndex} >
                    <ListItemIcon>
                        {_drawerItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={_drawerItem.name} />
                </ListItemButton>
            ))}
        </React.Fragment>
    );
}


