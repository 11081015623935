import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let resolve = undefined;

export default function useRemoteChangesAlertDialog() {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [title, setTitle] = React.useState("");



    const handleClose = (result) => {
        setOpen(false);
        resolve(result);
    };


    const dialog = React.useMemo(() => {
        return (
            <div><Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose("clickaway")}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => handleClose({ OK: false })}>Close</Button>
                    <Button color='secondary' onClick={() => handleClose({ OK: true, notifyUsers: false })}>Yes</Button>
                    <Button color='secondary' onClick={() => handleClose({ OK: true, notifyUsers: true })}>Yes & Notify Users</Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }, [open, title, message]);

    return [
        dialog,
        (_title, _message) => {
            setTitle(_title);
            setMessage(_message);
            setOpen(false);
            setTimeout(() => setOpen(true), 500);
            return new Promise((_resolve, _) => {
                resolve = _resolve;
            });
        }
    ];
}