import { Typography } from "@mui/material";
import Link from '@mui/material/Link';
import { brandLink, brandName } from "../consts";



export default function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href={brandLink}>
                {brandName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}