import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function InterstitialAdsDialog({ open, interstitialAds, onCancel, onSave }) {

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();


    // form
    const [active, setActive] = React.useState(true);
    const [showClickOnBottomNavBar, setShowClickOnBottomNavBar] = React.useState(true);
    const [iOSAdId, setiOSAdId] = React.useState("");
    const [androidAdId, setAndroidAdId] = React.useState("");
    const [intervalDuration, setIntervalDuration] = React.useState(0);


    React.useEffect(() => {
        if (interstitialAds) {
            setActive(interstitialAds.active);
            setShowClickOnBottomNavBar(interstitialAds.show_click_on_bottom_nav_bar);
            setiOSAdId(interstitialAds.iOS_ad_id);
            setAndroidAdId(interstitialAds.android_ad_id);
            setIntervalDuration(interstitialAds.interval_duration * 1);
            clearErrors();
        }
    }, [interstitialAds]);

    return (
        <div>
            <Dialog open={open} onClose={onCancel} >
                <DialogTitle>App Open Ads</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                                }
                                label="Active" className="text-primary" labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={showClickOnBottomNavBar} onChange={(e) => setShowClickOnBottomNavBar(e.target.checked)} name="showClickOnBottomNavBar" />
                                }
                                label="Show Click On Bottom Nav Bar" className="text-primary" labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="ios_ad_id"
                                color='primary'
                                helperText={"App Open Ad iOS ID"}
                                label="iOS Ad ID"
                                autoFocus
                                value={iOSAdId}
                                type="text"
                                id="ios_ad_id"
                                onChange={(e) => setiOSAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="android_ad_id"
                                color='primary'
                                helperText={"App Open Ad Android ID"}
                                label="Android Ad ID"
                                autoFocus
                                value={androidAdId}
                                type="text"
                                id="android_ad_id"
                                onChange={(e) => setAndroidAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                error={errors?.intervalDuration}
                                helperText={errors?.intervalDuration?.message ?? "Every interval time, it will show the ads repeatly. Unit is second."}
                                name="interval_duration"
                                color='primary'
                                label="Interval Duration"
                                autoFocus
                                value={intervalDuration}
                                {...register("intervalDuration", { min: { value: 0, message: "This fields must more than zero" } })}
                                type="number"
                                id="interval_duration"
                                onChange={(e) => setIntervalDuration(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={handleSubmit(() => {
                        var ads = {
                            "active": active,
                            "iOS_ad_id": iOSAdId,
                            "android_ad_id": androidAdId,
                            "interval_duration": intervalDuration * 1,
                            "show_click_on_bottom_nav_bar": showClickOnBottomNavBar
                        };
                        onSave(ads);
                    })}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
