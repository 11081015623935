import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ThemeProvider } from '@mui/system';
import { lightTheme } from '../theme';

export default function AddAppDialog(props) {
    const [open, setOpen] = React.useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();


    // form
    const [name, setName] = React.useState("");
    const [serviceAccount, setServiceAccount] = React.useState("");


    React.useEffect(() => { setOpen(props.open); }, [props.open]);

    return (
        <div>
            <ThemeProvider theme={lightTheme}>


                <Dialog open={open} onClose={props.onCancel}>
                    <DialogTitle>Add App</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    helperText={errors?.name?.message}
                                    error={errors?.name}
                                    fullWidth
                                    name="name"
                                    color='primary'
                                    label="Name"
                                    value={name}
                                    {...register("name", { required: 'This field is required', pattern: { message: "App name only can contains latters, and minus(-) expression", value: /^[a-zA-Z-]+$/g } })}
                                    type="text"
                                    id="name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    helperText={errors?.serviceAccount?.message}
                                    error={errors?.serviceAccount}
                                    name="serviceAccount"
                                    color='primary'
                                    label="Service Account"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("serviceAccount", {
                                        required: 'This field is required', validate: (file) => {
                                            return file[0].type === "application/json" ? true : "The file should be json format";

                                        }
                                    })}
                                    type="file"
                                    id="serviceAccount"
                                    onChange={(e) =>
                                        setServiceAccount(e.target.files[0])
                                    }
                                    autoComplete="serviceAccount"
                                />
                            </Grid>
                            <a rel="noreferrer" href="https://drive.google.com/file/d/1tZLWDL62PqREUk5YNS8J9x_UydykTTYS/view?usp=sharing" target={"_blank"} >Click to see how to get the <b>service account file!</b></a>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onCancel}>Cancel</Button>
                        <Button onClick={handleSubmit(() => {
                            var app = {
                                name,
                                serviceAccount,
                            };
                            props.onSave(app);
                        })}
                        >Save</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div >
    );
}
