import { Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function PushNotificationDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Push Notification</Typography>
                    <Divider />
                    You can push limitless <b>rich notification</b> to the users for free. This is an mass notification, <b>not targeted notification. </b>  If you want to know how to push targeted notification, please check <Link className="text-primary" to="/targeted-notification" >this article.</Link>
                    <br />
                    <br />
                    In the notification form, you should use URL for the image and link inputs. If you fill the link input, the link will be open in the app after user click to the notification.
                    <br />
                    <br />
                    You can easily fill the form, and push notification. Futher, you can check <a rel="noreferrer" href="https://drive.google.com/file/d/1oawB0T4P5oVLsCFTdEKRFfvQbqaQaxtr/view?usp=sharing" target={"_blank"} className="text-primary">this video</a> to see how it works.

                    <br />
                    <br />
                    <Typography variant="body" className="text-primary">
                        <Link to="/notifications-analytics">Notifications Analytics Article</Link>
                    </Typography>



                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}


