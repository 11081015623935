const NavBarStyles = [
    { name: "Style1", index: 1 },
    { name: "Style2", index: 2 },
    { name: "Style3", index: 3 },
    { name: "Style4", index: 4 },
    { name: "Style5", index: 5 },
    { name: "Style6", index: 6 },
    { name: "Style7", index: 7 },
    { name: "Style8", index: 8 },
    { name: "Style9", index: 9 },
    { name: "Style10", index: 10 },
    { name: "Style11", index: 11 },
    { name: "Style12", index: 12 },
    { name: "Style13", index: 13 },
    { name: "Style14", index: 14 },
    { name: "Style15", index: 15 },
    { name: "Style16", index: 16 },
    { name: "Style17", index: 17 },
    { name: "Style18", index: 18 },
    { name: "Neumorphic", index: 19 },
    { name: "Simple", index: 20 },
];

export default NavBarStyles;