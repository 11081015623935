
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { API_FUNCTIONS, getAnalyticsLocalStorageKey } from '../../consts';
import useAuthUser from '../AuthUser';
import useProjects from '../firestore/Projects';
import useSnackbar from '../Snackbar';



export default function useAnalytics() {
    const [snackbar, setSnackbar] = useSnackbar();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { fetchProject } = useProjects();
    const { user } = useAuthUser();
    const { project } = useParams();

    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }



    return {
        fetchAnalytics: async (projectName = project, userId = user.uid) => {
            _setUiStream("start_loading");


            var { propertyId } = await fetchProject(projectName, userId);

            if (!propertyId) {
                _setUiStream("stop_loading");
                setTimeout(() =>
                    _setUiStream("integration_is_not_completed_error")
                    , 10);
                setSnackbar("error", "Property ID is not found!");
                return;
            }

            var storageKey = getAnalyticsLocalStorageKey(project);

            if (localStorage[storageKey] != null) {
                _setUiStream("stop_loading");
                return JSON.parse(localStorage[storageKey]);
            }



            return API_FUNCTIONS.fetchAnalytics(user.uid, project, propertyId)
                .then(result => result.json())
                .then((result) => {
                    localStorage[storageKey] = JSON.stringify(result);
                    return result;
                }).catch(async (error) => {
                    setTimeout(() =>
                        _setUiStream("integration_is_not_completed_error")
                        , 10);
                    setSnackbar("error", error.message);
                    return;
                }).finally(() => {
                    _setUiStream("stop_loading");
                })
        },
        UiStream,
        components: [snackbar]
    };
}

/*

*/

/*
                    v */