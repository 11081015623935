import { Grid, ThemeProvider, Typography } from "@mui/material";
import { darkTheme } from "../theme";
import FormTemplate from "../components/FormTemplate";
import { Container } from "@mui/system";
import Header from "../components/Header";
import FirebasePanelLink from "../components/FirebasePanelLink";




export default function NotificationsAnalyticsDocument() {
    return (
        <ThemeProvider theme={darkTheme}>
            <FormTemplate>
                <Header breadcrumbList={[{ name: "Projects", link: "/projects" }, { name: "Notification Analytics", link: "/notifications-analytics" }]} ></Header>
                <Container style={{
                    "background": "white", "borderRadius": "12px", "color": "#4B4B4B"
                }} >
                    <Grid container px={"8px"} py={"16px"}>
                        <Grid item>
                            <Grid container justifyContent={"center"}>
                                <b style={{ "fontSize": "20px" }}>Notifications Analytics</b>
                            </Grid>
                            I will explain you how to access to the notifications analytics via the <FirebasePanelLink />.
                            <br />
                            <br />
                            What you gonna see on the notifications analytics ?
                            <br />
                            1. How many users received the notification
                            <br />
                            2. How many users display the notification
                            <br />
                            3. How many users click to open the notification.
                            <br />
                            <br />
                            To access to the firebase notification analytics, you need to go to the <FirebasePanelLink /> {"->"} Cloud Messaging{"(Side Menü)"} {"->"} Reports{"(Page Tab)"} {"->"} Export CSV{"(Button Below)"}.

                            After you download the CVS file, you will see the notifications analytics in it.


                        </Grid>
                    </Grid>

                </Container>
            </FormTemplate >
        </ThemeProvider >
    );
}


