
import { doc, getDoc, setDoc } from '@firebase/firestore';
import * as React from 'react';
import { FIRESTORE_COLLECTION_NAME } from '../../consts';
import { db } from '../../firebase';
import useAuthUser from '../AuthUser';
import useSnackbar from '../Snackbar';



export default function useRegisterPurchase() {
    const [snackbar, setSnackbar] = useSnackbar();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { user } = useAuthUser();

    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }

    const doFetchRegisterPurchase = (userId = user.uid) => {
        _setUiStream("start_loading");
        return getDoc(doc(db, FIRESTORE_COLLECTION_NAME, userId)).then((_doc) => _doc.exists() ? (_doc.data().registerPurchase ?? {}) : { vertification: false })
            .catch((error) => setSnackbar("error", error))
            .finally(() => _setUiStream("stop_loading"));
    }



    return {
        setRegisterPurchase: (platform, purchaseCode, userId = user.uid) => {
            _setUiStream("start_loading");
            return setDoc(doc(db, FIRESTORE_COLLECTION_NAME, userId), { "registerPurchase": { platform, purchaseCode, vertification: false } }, { merge: true }).catch((error) => setSnackbar("error", error))
                .finally(() => _setUiStream("stop_loading"));
        },
        fetchRegisterPurchase: async (userId) => {
            return await doFetchRegisterPurchase(userId);
        },
        UiStream,
        components: [snackbar]
    };
}

/*

*/

/*
                    v */