import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function UserAgentDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >User Agent</Typography>
                    <Divider />
                    You can catch the user agent on the website side easily. After looking the user agent of the user, you will understand if the user coming from android and ios app. Based upon that, you can customize the app to the android and ios users.
                    <br />
                    <br />
                    <b>By Android User Agent:</b> you can understand the users comes from the android app, and customize the website to the app users
                    <br />
                    <br />
                    <b>By iOS User Agent:</b> you can understand the users comes from the iOS app, and customize the website to app users
                    <br />
                    <br />
                    <b>Note: </b> If you don't know how to access the user agent of the user on website side, It depends on the technology you are using on your side. You can google about it.
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

