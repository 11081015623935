import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function StatusBarDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Status Bar</Typography>
                    <Divider />
                    Status Bar stands on the most top of the app screen. It contains wifi, battery, hour indicators in it. You can search about it on google.

                    <br />
                    <br />

                    <b>My Advices:</b> you should design the status bar's color compatible to your app desing,  specially your app bar or the header section of your website.

                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/1k0BvO4QukBcgiZZgxau2zINYXIdN2lJ-/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the status bar image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

