import useAuth from "../hooks/AuthUser";

export default function UsernameText() {

    const { user } = useAuth();

    return (
        <div>
            {user && user.email?.split("@")[0]}
        </div>
    );
}
