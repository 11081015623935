import { Button, ButtonGroup, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import NavBarStyles from "../../NavBarStyles";
import ProjectFormTemplate from "../ProjectFormTemplate";
import BottomNavigationBarDialog from "./BottomNavigationBarDialog";

import ListFormControl from "../../../../ListFormControl";
import AddIcon from '@mui/icons-material/Add';
import useSnackbar from "../../../../../hooks/Snackbar";
import { SAVE_ALERT } from "../../../../../consts";
import useRemoteConfig from "../../../../../hooks/backend/RemoteConfig";



export default function BottomNavigationBarForm() {
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);
    const [snackbar, openSnackbar] = useSnackbar();

    // form
    const [active, setActive] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("");
    const [styleNo, setStyleNo] = useState(16);
    const [initialIndex, setInitialIndex] = useState(2);
    const [listenWebview, setListenWebview] = useState(false);
    const [hideOnThisURLs, setHideOnThisURLs] = useState([]);
    const [navBarItems, setNavBarItems] = useState([]);


    // helper
    const [selectecNavBarItem, setSelectecNavBarItem] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);




    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.bottom_navigation_bar) {
            setActive(remoteConfig.bottom_navigation_bar.active);
            setBackgroundColor(remoteConfig.bottom_navigation_bar.background_color);
            setStyleNo(remoteConfig.bottom_navigation_bar.style_no);
            setInitialIndex(remoteConfig.bottom_navigation_bar.initial_index);
            setListenWebview(remoteConfig.bottom_navigation_bar.listen_webview);
            setHideOnThisURLs(remoteConfig.bottom_navigation_bar.hide_on_this_urls);
            setNavBarItems(remoteConfig.bottom_navigation_bar.navbar_items);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.bottom_navigation_bar.active = active;
        remoteConfig.bottom_navigation_bar.background_color = backgroundColor;
        config.bottom_navigation_bar.style_no = styleNo;
        config.bottom_navigation_bar.initial_index = initialIndex;
        config.bottom_navigation_bar.listen_webview = listenWebview;
        config.bottom_navigation_bar.hide_on_this_urls = hideOnThisURLs;
        config.bottom_navigation_bar.navbar_items = navBarItems;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="background_color"
                        color='primary'
                        helperText={"Background color of the navigation bar"}
                        label="Background Color"
                        value={backgroundColor}
                        type="color"
                        id="background_color"
                        onChange={(e) => setBackgroundColor(e.target.value)}
                        autoComplete="background_color"
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Style No</InputLabel>
                        <Select
                            labelId="style_no_label"
                            id="style_no_label_select"
                            value={styleNo}
                            autoFocus
                            label="Style No"
                            onChange={(e) => setStyleNo(e.target.value)}
                        >
                            {NavBarStyles.map((navbarStyle) => (<MenuItem key={navbarStyle.index} value={navbarStyle.index}>{navbarStyle.name}</MenuItem>))}

                        </Select>
                        <FormHelperText>
                            Please select a bottom bar menu style in this <a rel="noreferrer" href="https://pub.dev/packages/persistent_bottom_nav_bar" target={"_blank"} className="text-primary" >link</a>
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Initial Button</InputLabel>
                        <Select
                            labelId="initial_index_id"
                            id="initial_index_select"
                            value={initialIndex}
                            label="Initial Button"
                            onChange={(e) => setInitialIndex(e.target.value)}
                        >
                            {navBarItems.map((navbarItem, index) => (<MenuItem key={index} value={index}>{navbarItem.item.title}</MenuItem>))}

                        </Select>
                        <FormHelperText>
                            Select the initial selected button of bottom menu bar.
                        </FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ListFormControl
                        label="Hide on This URL"
                        helperText="Bottom navigation bar will be invisible at these urls"
                        list={hideOnThisURLs}
                        onAdd={(_argNewItem) => {
                            if (hideOnThisURLs) {
                                setHideOnThisURLs([...hideOnThisURLs, _argNewItem]);

                            } else {
                                setHideOnThisURLs([_argNewItem]);
                            }
                        }}
                        onDelete={(_argItem) => {
                            setHideOnThisURLs(hideOnThisURLs.filter(v => v !== _argItem));
                        }}
                    />
                </Grid>

                <Grid item xs={12} >
                    <FormControl fullWidth>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <FormLabel>Bottom Bar Buttons</FormLabel>

                            </Grid>
                            <Grid item>
                                <IconButton color="primary" onClick={() => {
                                    if (navBarItems.length > 5) {
                                        alert("Can not  be added more than six buttons!");
                                        return;
                                    }
                                    setSelectecNavBarItem({});
                                    setDialogOpen(true);
                                }} ><AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <ButtonGroup fullWidth orientation="horizontal" aria-label="outlined button group">
                            {navBarItems.map((navBarItem, index) => (<Button key={index} variant={index === initialIndex ? "contained" : "outlined"} onClick={() => {
                                setSelectecNavBarItem(navBarItem);
                                setDialogOpen(true);
                            }}>{navBarItem.item.title}</Button>))}
                        </ButtonGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <BottomNavigationBarDialog
                remoteConfig={remoteConfig}
                open={dialogOpen}
                navBarItem={selectecNavBarItem}
                onDelete={() => {
                    setNavBarItems(navBarItems.filter(v => v.item.title !== selectecNavBarItem.item.title));
                    setDialogOpen(false);
                }}
                onSave={(_argNavBarItem) => {
                    var index = navBarItems.indexOf(selectecNavBarItem);
                    if (index !== -1) {
                        navBarItems[index] = _argNavBarItem;
                        setNavBarItems(navBarItems);
                    }
                    else {
                        setNavBarItems([...navBarItems, _argNavBarItem]);
                    }
                    setDialogOpen(false);
                    openSnackbar("success", SAVE_ALERT);
                }}
                onCancel={() => {
                    setDialogOpen(false);
                    setSelectecNavBarItem({});
                }}
            />
            {snackbar}
        </ProjectFormTemplate >
    );
}



