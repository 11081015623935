import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";





export default function JavascriptAndCSSForm(props) {

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [javascriptUrl, setJavascriptUrl] = useState("");
    const [cssUrl, setCSSUrl] = useState("");

    useEffect(() => {
        if (remoteConfig && remoteConfig.preferences) {
            setJavascriptUrl(remoteConfig.preferences.javascript_url);
            setCSSUrl(remoteConfig.preferences.css_url);
            setLoading(false);
        }
    }, [remoteConfig]);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);



    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.preferences.javascript_url = javascriptUrl;
        config.preferences.css_url = cssUrl;
        setTimeout(() => updateRemoteConfig(config), 500);
    };

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSaveButton}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        helperText={"You can add JS logic to the app by providing the JS file url. JS file will execute for each web pages"}
                        name="javascript_url"
                        color='primary'
                        label="Javascript URL"
                        value={javascriptUrl}
                        type="url"
                        id="javascript_url"
                        onChange={(e) => setJavascriptUrl(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="css_url"
                        helperText={"You can add CSS style to the app by providing the CSS file url. CSS file will execute for each web pages"}
                        color='primary'
                        label="CSS URL"
                        value={cssUrl}
                        type="url"
                        id="css_url"
                        onChange={(e) => setCSSUrl(e.target.value)}
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate >


    );
}

/*
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        error={errors?.configFetchInterval}
                        helperText={errors?.configFetchInterval?.message ?? "60 is one minute. Users will fetch the latest configuration props based on this time."}
                        name="config_fetch_intervak"
                        color='primary'
                        label="Config Fetch Interval"
                        value={configFetchInterval}
                        {...register("configFetchInterval", { min: { value: 0, message: "This fields must more than zero" } })}
                        type="number"
                        id="config_fetch_intervak"
                        onChange={(e) => setConfigFetchInterval(e.target.value)}
                    />
                </Grid>
*/