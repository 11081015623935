import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function AppOpenAdsDialog({ open, appOpenAds, onCancel, onSave }) {

    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();


    // form
    const [active, setActive] = React.useState(true);
    const [iOSAdId, setiOSAdId] = React.useState("");
    const [androidAdId, setAndroidAdId] = React.useState("");
    const [maxCacheDuration, setMaxCacheDuration] = React.useState(0);


    React.useEffect(() => {
        if (appOpenAds) {
            setActive(appOpenAds.active);
            setiOSAdId(appOpenAds.iOS_ad_id);
            setAndroidAdId(appOpenAds.android_ad_id);
            setMaxCacheDuration(appOpenAds.max_cache_duration * 1);
            clearErrors();
        }
    }, [appOpenAds]);

    return (
        <div>
            <Dialog open={open} onClose={onCancel}  >
                <DialogTitle>App Open Ads</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                                }
                                label="Active" className="text-primary" labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="ios_ad_id"
                                color='primary'
                                helperText={"App Open Ad iOS ID"}
                                label="iOS Ad ID"
                                autoFocus
                                value={iOSAdId}
                                type="text"
                                id="ios_ad_id"
                                onChange={(e) => setiOSAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                name="android_ad_id"
                                color='primary'
                                helperText={"App Open Ad Android ID"}
                                label="Android Ad ID"
                                autoFocus
                                value={androidAdId}
                                type="text"
                                id="android_ad_id"
                                onChange={(e) => setAndroidAdId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                fullWidth
                                error={errors?.maxCacheDuration}
                                helperText={errors?.maxCacheDuration?.message ?? " After the duration time, If the user reopen the app from background, the ad will be popup. Unit is second."}
                                name="max_cache_duration"
                                color='primary'
                                label="Max Cache Duration"
                                autoFocus
                                value={maxCacheDuration}
                                {...register("maxCacheDuration", { min: { value: 0, message: "This fields must more than zero" } })}
                                type="number"
                                id="max_cache_duration"
                                onChange={(e) => setMaxCacheDuration(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={handleSubmit(() => {
                        var ads = {
                            "active": active,
                            "iOS_ad_id": iOSAdId,
                            "android_ad_id": androidAdId,
                            "max_cache_duration": maxCacheDuration * 1
                        };
                        onSave(ads);
                    })}
                    >Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
