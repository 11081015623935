import { CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useRemoteConfig from '../../hooks/backend/RemoteConfig';
import { darkTheme } from '../../theme';

export const ProjectDispatcher = () => {
    const navigate = useNavigate();
    const { remoteConfig } = useRemoteConfig();
    const { project } = useParams();


    useEffect(() => {
        if (remoteConfig) {
            const version = `v${remoteConfig.webvify_version}`;
            navigate(`/${version}/project/${project}/general-settings`);
        }
    }, [remoteConfig]);



    return (
        <Grid container sx={{ height: document.documentElement.clientHeight, background: darkTheme.palette.background.default }} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
                <CircularProgress sx={{ color: "white" }} />
            </Grid>
        </Grid>
    );
};