import { CircularProgress, Grid } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
import { getAuthUser, runAsync } from '../../helpers';
import { darkTheme } from '../../theme';

export var authToken = { 'authorization': "" };

export const AuthContext = createContext(null);

var userInitial = { initial: true };

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [user, setUser] = useState(userInitial);
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [loading, setLoading] = useState(true);

    const handleLogin = async (_user) => {
        setUser(_user);
        navigate("/projects");
    };

    const handleLogout = async () => {
        await getAuth().signOut();
        setUser(userInitial);
        setIsAuthenticated(false);
        navigate(`/signin`);
    };

    useEffect(() => {
        runAsync(async () => {
            var _user = await getAuthUser();
            await setUser(_user);
            authToken.authorization = await _user.getIdToken();
        });
    }, []);

    useEffect(() => {
        if (user && user.initial) {
            return;
        }
        setLoading(false);
        setIsAuthenticated(user != undefined);
    }, [user]);




    const value = {
        user,
        isAuthenticated,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    var loadingScreen = (
        <Grid container sx={{ height: document.documentElement.clientHeight, background: darkTheme.palette.background.default }} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
                <CircularProgress sx={{ color: "white" }} />
            </Grid>
        </Grid>
    );

    return (
        <AuthContext.Provider value={value}>
            {loading ? loadingScreen : children}
        </AuthContext.Provider>
    );
};