import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import ProjectFormTemplate from "./ProjectFormTemplate";



export default function LoadingScreenForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);


    // form

    const [active, setActive] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [indicatorColor, setIndicatorColor] = useState("#ffffff");
    const [loadingScreenThreshold, setLoadingScreenThreshold] = useState(100);


    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);

    useEffect(() => {
        if (remoteConfig && remoteConfig.loading_screen) {
            setActive(remoteConfig.loading_screen.active);
            setBackgroundColor(remoteConfig.loading_screen.background_color);
            setIndicatorColor(remoteConfig.loading_screen.color);
            setLoadingScreenThreshold(remoteConfig.loading_screen.hide_loading_screen_at);
            setLoading(false);
        }
    }, [remoteConfig]);

    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        remoteConfig.loading_screen.active = active;
        remoteConfig.loading_screen.background_color = backgroundColor;
        remoteConfig.loading_screen.color = indicatorColor;
        remoteConfig.loading_screen.hide_loading_screen_at = loadingScreenThreshold * 1;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch checked={active} onChange={(e) => setActive(e.target.checked)} name="active" />
                        }
                        label="Active" className="text-primary" labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="background_color"
                        color='primary'
                        label="Background Color"
                        value={backgroundColor}
                        type="color"
                        id="background_color"
                        onChange={(e) => setBackgroundColor(e.target.value)}
                        autoComplete="background_color"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="indicator_color"
                        color='primary'
                        label="Indicator Color"
                        value={indicatorColor}
                        type="color"
                        id="indicator_color"
                        onChange={(e) => setIndicatorColor(e.target.value)}
                        autoComplete="indicator_color"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        error={errors?.loadingScreenThreshold}
                        helperText={errors?.loadingScreenThreshold?.message ?? "Loading screen will disappear once website's page loaded above 50%"}
                        name="loading_screen_threshold"
                        color='primary'
                        label="Loading Screen Threshold"
                        value={loadingScreenThreshold}
                        type="number"
                        id="loading_screen_threshold"
                        {...register("loadingScreenThreshold", { min: { value: 0, message: "This fields must more than zero" }, max: { value: 100, message: "This fields must less than 100" } })}
                        onChange={(e) => setLoadingScreenThreshold(e.target.value)}
                        autoComplete="loading_screen_threshold"
                    />
                </Grid>

            </Grid>
        </ProjectFormTemplate>
    );
}