import { Divider, Grid, Typography } from "@mui/material";
import ProjectDocumentTemplate from "./ProjectDocumentTemplate";



export default function LoadingScreenDocument(props) {
    return (
        <ProjectDocumentTemplate>
            <Grid container>
                <Grid item xs={12} sx={{ textAlign: "justify" }}>
                    <br />
                    <Typography variant="h5" >Loading Screen</Typography>
                    <Divider />
                    Loading screen is working right after splash screen. It is always appear, while a page is loading on website. You can disable it if your website already have an built-in loading screen.
                    <br />
                    <br />
                    <b>My Advices:</b> I am always make loading indicator the same as primary color. And 50 is okay for the Loading screen thresold. If it is 50, The loading screen will disappear once website's page loaded above 50%. In this way, the website will look like loading more performant
                    <br />
                    <br />
                    <a rel="noreferrer" href="https://drive.google.com/file/d/18F79NMamAg7Ot7tNkEOxaxlyaZbmVF6I/view?usp=sharing" target={"_blank"} className="text-primary">Click to see the loading screen image!</a>
                </Grid>
            </Grid>
        </ProjectDocumentTemplate>
    );
}

