
import { ref, uploadBytes } from 'firebase/storage';
import * as React from 'react';
import { storage } from '../../firebase';
import useAuth from '../AuthUser';
import useSnackbar from '../Snackbar';



export default function useServiceAccount() {
    const [snackbar, setSnackbar] = useSnackbar();
    const [UiStream, setUiStream] = React.useState({ id: 0, value: "initial" });
    const { user } = useAuth();


    const _setUiStream = (value) => {
        setUiStream({ id: UiStream.id + 1, value });
    }



    return {
        uploadServiceAccount: async (projectName, userId = user.uid, serviceAccount) => {
            _setUiStream("start_loading");
            const storageRef = ref(storage, `/${userId}/${projectName}/service_account.json`);
            await uploadBytes(storageRef, serviceAccount).then((result) => {
                return result;
            }).catch(async (error) => {
                setSnackbar("error", error.message);
                return;
            }).finally(() => {
                _setUiStream("stop_loading");
            });
        },
        UiStream,
        components: [snackbar]
    };
}

/*

*/

/*
                    v */